import React, { useState, useEffect } from "react";
import "./submitporposal.scss";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import UserProposalSimple from "../../hooks/dataSenders/userproposeSimple";
import TextEditor from "./TextEditor";
import AmountApproveSimple from "../../hooks/dataSenders/approveSimple";
import {
  useBaseFeeGet,
  CheckAllowance,
  useTomiBalance,
  useMinProReq,
  useNftUsed,
} from "../../hooks/useBalances";
import Loader from "../../hooks/loader";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Environment from "../../utils/Environment";

function SimpleProposal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { account } = useWeb3React();
  const history = useHistory();
  const [mainLoader, setMainLoader] = useState(false);
  const [userTomiBalance, setUserTomiBalance] = useState(0);
  const [realPrice, setRealPrice] = useState(0);
  const [allowance, setAllowance] = useState();
  const { UserProposeContrct } = UserProposalSimple();
  const proposalBaseFee = useBaseFeeGet();
  const minReqNFtPro = useMinProReq();
  const { UserApprove } = AmountApproveSimple();
  const { CheckUserBalance } = useTomiBalance();
  const { CheckAllow } = CheckAllowance();
  const { CheckNftUsed } = useNftUsed();
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    length: 0,
  });
  // console.log("inputsss", minReqNFtPro);
  const setEditor = (data) => {
    setInputs({ ...inputs, description: data, length: data.length - 8 });
  };
  // console.log("vakue we get here is", userTomiBalance, realPrice);

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const gata = async () => {
    let balance = await CheckUserBalance();
    setUserTomiBalance(balance);
    let allowed = await CheckAllow(account, Environment.CreateProposal);
    setAllowance(allowed);
  };

  const userNFt = () => {
    if (account) {
      const options = {
        method: "GET",
        url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.MintContract}`,
        headers: {
          accept: "application/json",
          "X-API-Key": "skMp4F6qEu9nqR6jnLUnBW7F1PfEZkptx6kTbh5CoLBE6zD4Twv5KPCI48HGICQM",
        },
      };

      const a = axios.request(options).then(async function (response) {
        // console.log(response.data.result);
        let dummArr = [];
        let promise = await Promise.all(
          response?.data?.result.map((data) => {
            return CheckNftUsed(data?.token_id);
          })
        );
        let a = 0;
        for (let i = 0; i < promise?.length; i++) {
          // console.log("ABBBBB111loooop", promise[i]);
          if (promise[i] === false && a < minReqNFtPro) {
            a = a + 1;
            dummArr.push(response?.data?.result[i]?.token_id);
          }
        }
        // setUserAllNft(dummArr);
        return response.data.result;
      });
      return a.catch(function (error) {
        console.error(error);
      });
    }
  };

  // Approve Amount fee
  const Approve = async () => {
    try {
      setMainLoader(true);
      let res = await UserApprove(account);
      if (res) {
        setMainLoader(false);
        let allowed = await CheckAllow(account, Environment.CreateProposal);
        setAllowance(allowed);
        let target = [];
        let values = [0];
        let signature = ["proposeContentModeration(string,uint256,string,uint256)"];
        // target.push(Environment.TomiMainDao)
        try {
          setMainLoader(true);
          const res = await UserProposeContrct(
            target,
            values,
            inputs?.description.toString(),
            inputs?.title
          );
          setTimeout(() => {
            // console.log("time set out");
            setMainLoader(false);
            window.$("#exampleModal").modal("hide");
            window.$("#exampleModal1").modal("show");
          }, "10000");
        } catch (error) {
          setMainLoader(false);
          window.$("#exampleModal").modal("hide");
          let a = error.message.slice(19, 58);
          toast.error(a);
          console.log("eerrr", error);
        }
      } else {
        setMainLoader(false);
        window.$("#exampleModal").modal("hide");
      }
    } catch (err) {
      setMainLoader(false);
      console.log("eerrrr", err);
    }
  };

  useEffect(() => {
    gata();
    axios
      .get(
        "https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
      )
      .then((res) => {
        setRealPrice(res?.data?.tominet?.usd);
      });
  }, [account, allowance]);

  useEffect(() => {
    gata();
  }, []);

  //modal sucess failure
  const opeenModal = (e) => {
    if (userTomiBalance >= proposalBaseFee / realPrice) {
      window.$("#exampleModal").modal("show");
    } else {
      window.$("#exampleModal2").modal("show");
    }
  };

  // Modal opening function
  const successs = () => {
    window.$("#exampleModal1").modal("hide");
    history.push("/home");
  };
  const successs1 = () => {
    window.$("#exampleModal2").modal("hide");
  };

  //submit proposal
  const submitProposal = async () => {
    // setMainLoader(true)
    if (account) {
      let target = [];
      let values = [0];
      let signature = ["proposeContentModeration(string,uint256,string,uint256)"];
      target.push(Environment.TomiMainDao);
      if (allowance < proposalBaseFee / realPrice) {
        Approve();
      } else {
        try {
          setMainLoader(true);
          const res = await UserProposeContrct(
            target,
            values,
            inputs?.description.toString(),
            inputs?.title
          );
          setTimeout(() => {
            // console.log("time set out");
            setMainLoader(false);
            window.$("#exampleModal").modal("hide");
            window.$("#exampleModal1").modal("show");
          }, "10000");
        } catch (error) {
          setMainLoader(false);
          window.$("#exampleModal").modal("hide");
          let a = error.message.slice(18, 59);
          toast.error(a);
          console.log("eerrr", error);
        }
      }
    } else {
      setMainLoader(false);
      toast.error("Connect Your Wallet");
    }
  };

  return (
    <>
      {mainLoader && <Loader />}
      <section className="main-submit">
        <img src="\assets\neww.png" alt="img" className="proposalbgimg" />
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              <div className="row">
                <div className="col-lg-9 m-auto p-0">
                  <div className="innersubmit">
                    <div className="main-heading">
                      <h2>Submit Proposal</h2>
                      <div className="proposal_mainss">
                        <div className="active-content">
                          <Link to="/simpleproposal">
                            <button className="active-btn-show">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 13H12"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 17H16"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              General Proposal
                            </button>
                          </Link>
                          <Link to="/addfund">
                            <button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <path
                                  d="M14.4737 6.43962V10.3021C14.4737 12.6121 13.1538 13.6021 11.1738 13.6021H4.58125C4.24375 13.6021 3.92125 13.5722 3.62125 13.5047C3.43375 13.4747 3.25375 13.4222 3.08875 13.3622C1.96375 12.9422 1.28125 11.9671 1.28125 10.3021V6.43962C1.28125 4.12962 2.60125 3.13965 4.58125 3.13965H11.1738C12.8538 3.13965 14.0613 3.85214 14.3838 5.47964C14.4363 5.77964 14.4737 6.08712 14.4737 6.43962Z"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M16.7236 8.68947V12.552C16.7236 14.862 15.4036 15.852 13.4236 15.852H6.83109C6.27609 15.852 5.7736 15.777 5.3386 15.612C4.4461 15.282 3.83859 14.5995 3.62109 13.5045C3.92109 13.572 4.24359 13.602 4.58109 13.602H11.1736C13.1536 13.602 14.4736 12.612 14.4736 10.302V6.43947C14.4736 6.08697 14.4436 5.77199 14.3836 5.47949C15.8086 5.77949 16.7236 6.78447 16.7236 8.68947Z"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.87064 10.3546C8.96416 10.3546 9.85065 9.46807 9.85065 8.37454C9.85065 7.28102 8.96416 6.39453 7.87064 6.39453C6.77711 6.39453 5.89062 7.28102 5.89062 8.37454C5.89062 9.46807 6.77711 10.3546 7.87064 10.3546Z"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M3.58203 6.72461V10.0246"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.1641 6.72461V10.0246"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Funding Proposal
                            </button>
                          </Link>
                          <Link to="/addcontraction">
                            <button >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <path
                                  d="M6.75063 10.25C6.25563 10.4975 5.84313 10.865 5.53563 11.3225C5.36312 11.585 5.36312 11.915 5.53563 12.1775C5.84313 12.635 6.25563 13.0025 6.75063 13.25"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.4062 10.25C11.9012 10.4975 12.3137 10.865 12.6212 11.3225C12.7937 11.585 12.7937 11.915 12.6212 12.1775C12.3137 12.635 11.9012 13.0025 11.4062 13.25"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V7.25C16.5 3.5 15 2 11.25 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17Z"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.67188 6.5075L16.0869 6.5"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Technical Update Proposal
                            </button>
                          </Link>
                        </div>
                        <div>
                          {/* <p>Title</p> */}
                          <input
                            type="text"
                            name="title"
                            placeholder="Enter title"
                            onChange={handleChange1}
                          />
                          <div className="textareadescription">
                            <div className="form-group">
                              {/* <label>Description</label> */}
                              <TextEditor onEditorChange={setEditor} />
                              {inputs.length >= 10 ? (
                                ""
                              ) : (
                                <p className="error">Minimum 10 Characters required </p>
                              )}
                            </div>
                            <div className="submitbutton">
                              {inputs.length >= 10 && inputs.title !== "" ? (
                                userTomiBalance >= minReqNFtPro ? (
                                  <button className="aaa" onClick={opeenModal}>
                                    Submit Proposal
                                  </button>
                                ) : (
                                  <p className="error">You dont have {minReqNFtPro} Tokens</p>
                                )
                              ) : (
                                <p className="error">Fields Cannot be Empty</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Insufficient Modal */}
      <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Confirm Transaction</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg
                      class="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M19.281 18.7198C19.3507 18.7895 19.406 18.8722 19.4437 18.9632C19.4814 19.0543 19.5008 19.1519 19.5008 19.2504C19.5008 19.349 19.4814 19.4465 19.4437 19.5376C19.406 19.6286 19.3507 19.7114 19.281 19.781C19.2114 19.8507 19.1286 19.906 19.0376 19.9437C18.9465 19.9814 18.849 20.0008 18.7504 20.0008C18.6519 20.0008 18.5543 19.9814 18.4632 19.9437C18.3722 19.906 18.2895 19.8507 18.2198 19.781L12.0004 13.5607L5.78104 19.781C5.64031 19.9218 5.44944 20.0008 5.25042 20.0008C5.05139 20.0008 4.86052 19.9218 4.71979 19.781C4.57906 19.6403 4.5 19.4494 4.5 19.2504C4.5 19.0514 4.57906 18.8605 4.71979 18.7198L10.9401 12.5004L4.71979 6.28104C4.57906 6.14031 4.5 5.94944 4.5 5.75042C4.5 5.55139 4.57906 5.36052 4.71979 5.21979C4.86052 5.07906 5.05139 5 5.25042 5C5.44944 5 5.64031 5.07906 5.78104 5.21979L12.0004 11.4401L18.2198 5.21979C18.3605 5.07906 18.5514 5 18.7504 5C18.9494 5 19.1403 5.07906 19.281 5.21979C19.4218 5.36052 19.5008 5.55139 19.5008 5.75042C19.5008 5.94944 19.4218 6.14031 19.281 6.28104L13.0607 12.5004L19.281 18.7198Z"
                        fill="#0E1120"
                      />
                    </svg>
                  </button>
                </div>
                <div className="upper-img text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="101"
                    viewBox="0 0 100 101"
                    fill="none"
                  >
                    <circle cx="50" cy="50.5" r="50" fill="#EFE2F6" />
                    <path
                      d="M49.8086 24.8594V64.578"
                      stroke="#FF0083"
                      stroke-width="7"
                      stroke-linecap="round"
                    />
                    <circle cx="49.8096" cy="73.126" r="3.0166" fill="#FF0083" />
                  </svg>
                </div>
                <div className="submitfee-text">
                  <h6>Are you sure?</h6>
                  <p>Submit Proposal fee Paid to DAO</p>
                  <h5>
                    {" "}
                    worth of TomiToken Required <span>${proposalBaseFee}</span>
                  </h5>
                  <button onClick={submitProposal}>Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Transaction Success Modal===============> */}
      <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal1"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Confirm Transaction</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="\landing\entypo_cross.png" className="img-fluid crossimg" />
                  </button>
                </div>
                <div className="success">
                  <img src="\landing\confirmtransaction.svg" className="img-fluid mb-4" />
                  <p>Transaction Successful!</p>
                </div>
                <div className="confirmbtn">
                  <button onClick={successs}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Insufficient Funds</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="\landing\entypo_cross.png" className="img-fluid crossimg" />
                  </button>
                </div>
                <div className="success">
                  <div className="upper-img text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="101"
                      viewBox="0 0 100 101"
                      fill="none"
                    >
                      <circle cx="50" cy="50.5" r="50" fill="#EFE2F6" />
                      <path
                        d="M49.8086 24.8594V64.578"
                        stroke="#FF0083"
                        stroke-width="7"
                        stroke-linecap="round"
                      />
                      <circle cx="49.8096" cy="73.126" r="3.0166" fill="#FF0083" />
                    </svg>
                  </div>
                  <p>Transaction Failed!</p>
                  <p className="">
                    ${proposalBaseFee} <span> worth of TomiToken Required</span>
                  </p>
                </div>
                <div className="confirmbtn">
                  <button onClick={successs1}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //sumitporposal modal/// */}
      {/* <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Confirm Transaction</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="\landing\entypo_cross.png" className="img-fluid crossimg" />
                  </button>
                </div>
                <div className="error-transaction">
                  <div className="upper-img text-center">
                    <img src="\landing\qussss.png" alt="img" className="img-fluid taggeeeumh" />
                  </div>
                  <div className="submitfee-text">
                    <h1 className="surediv">Are you sure?</h1>
                    <h6>Submit Proposal Fee Paid To DAO</h6>
                    <h4 className="mb-4 mydvvDDvvvv">
                      ${proposalBaseFee} <span> worth of TomiToken</span>
                    </h4>
                    <button onClick={submitProposal}>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default SimpleProposal;
