import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import {
  UserTreasure,
  UserTreasureEth,
  UserTreasureUsd,
  useTomiBalance,
  useVotes,
  useMinProReq,
} from "../../hooks/useBalances";
import Countdown from "react-countdown";

import "./banner.scss";
import Modal from "react-bootstrap/Modal";
import Environment from "../../utils/Environment";
import useAuth from "../../hooks/useAuth";
import { browserName } from "react-device-detect";
import NavbarNew from "../NavbarNew/NavbarNew";
import Navbar from "../Navbar/Navbar";
function Banner() {
  const [treeasure, setTreeasure] = useState(0);
  const { CheckUserBalance } = useTomiBalance();

  const UserWalleet1 = UserTreasureEth();
  const UserWalleet2 = UserTreasureUsd();
  const UserWalleet = UserTreasure();
  const { account } = useWeb3React();
  const minReqNFtPro = useMinProReq();
  const [realPrice, setRealPrice] = useState(0);
  const [userTomiBalance, setUserTomiBalance] = useState(0);
  const [allProposals, setAllProposals] = useState([]);
  const [tomiPrice, setTomiPrice] = useState(0);
  const [tomiSupply, setTomiSupply] = useState(0);
  const [proposalData, setProposalData] = useState([]);
  let res2 = UserWalleet;
  // console.log("votesss", myVotes, userTomiBalance);
  const VoteAvail = useVotes(account);

  const Gata = async () => {
    let balance = await CheckUserBalance();
    // console.log("userrernft", VoteAvail);
    setUserTomiBalance(balance);
  };

  // forVotes
  // againstVotes
  // abstainVotes
  // state

  const getProposals = async () => {
    // setMainLoader(true);
    var data = JSON.stringify({
      query: `{
            proposalCreateds(orderBy: proposalId, orderDirection: desc) {
              id
              proposalId
              proposer
              targets
              values
              signatures
              calldatas
              startTime
              endTime
              title
              state
              description
              quorumVotes
              consensusVotes
                forVotes
    againstVotes
    abstainVotes
            }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/63987/tomi-dao-live/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // setAllProposals(response?.data?.data?.proposalCreateds);
        setAllProposals(
          response?.data?.data?.proposalCreateds.filter((proposal) => proposal.proposalId !== "21")
        );

        //   console.log("ressssss",response.data.data)
      })
      .catch(function (error) {
        console.log(error);
        // setMainLoader(false);
      });
  };

  const getProposalsData = async () => {
    // console.log("indata");
    // setMainLoader(true);
    var data = JSON.stringify({
      query: `{
        datas {
          id
          proposalsCount
          votesCount
        }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: " https://api.studio.thegraph.com/query/63987/tomi-dao-live/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        setProposalData(response?.data?.data?.datas[0]);

        // console.log("ressssss", response?.data?.data?.datas[0])
      })
      .catch(function (error) {
        console.log(error);
        // setMainLoader(false);
      });
  };

  const myProposal = allProposals?.map((elem, b) => {
    let status1 = "";
    if (elem?.status === 0) {
      status1 = "Pending";
    } else if (elem?.status === 1) {
      status1 = "Active";
    } else if (elem?.status === 2) {
      status1 = "Canceled";
    } else if (elem?.status === 3) {
      status1 = "Defeated";
    } else if (elem?.status === 4) {
      status1 = "Succeeded";
    } else if (elem?.status === 5) {
      status1 = "Queued";
    } else if (elem?.status === 6) {
      status1 = "Expired";
    } else if (elem?.status === 7) {
      status1 = "Executed";
    } else {
      status1 = "Vetoed";
    }

    // console.log("elememmmm", elem, elem?.consensusVotes <= parseInt(elem?.forVotes));
    let a = Date.now() / 1000;
    // console.log("a", allProposals.length, b);
    let all = elem?.proposalId >= 21 ? elem?.proposalId - 1 : elem?.proposalId;

    //
    return (
      <a href={"/proposal/" + all}>
        <div className="timeDiv cursorrr">
          <div className="banner_parent_Div">
            <div className="noDiv">
              <div className="button_top">
                <h3 className="ptagssspan"> Proposal {allProposals.length - b}</h3>
                <div className="d-flex align-items-center justify-content-md-end mt-md-0 excutedtext">
                  {a < elem.startTime ? (
                    <Link to={"/proposal/" + all}>
                      {" "}
                      <button className="greyButtons border-0 bg-secondary ">Pending</button>
                    </Link>
                  ) : a < elem.endTime ? (
                    <Link to={"/proposal/" + all}>
                      {" "}
                      <button className="greyButtons border-0 bg-secondary">Pending</button>
                    </Link>
                  ) : (
                    <Link to={"/proposal/" + all}>
                      {" "}
                      <button
                        className={
                          elem.state === "ACTIVE"
                            ? "greyButtons border-0 bg-primary "
                            : elem.state === "PENDING"
                            ? "greyButtons border-0 bg-secondary "
                            : parseInt(elem?.consensusVotes / 10 ** 18) > parseInt(elem?.forVotes)
                            ? "greyButtons border-0 bg-danger"
                            : "greyButtons border-0 bg-success "
                        }
                      >
                        {/* Pending */}
                        {elem?.state === "ACTIVE"
                          ? "Queued"
                          : elem?.state === "PENDING"
                          ? "Pending"
                          : parseInt(elem?.consensusVotes / 10 ** 18) <= parseInt(elem?.forVotes)
                          ? "Executed"
                          : parseInt(elem?.consensusVotes / 10 ** 18) > parseInt(elem?.forVotes)
                          ? "Defeated"
                          : ""}
                      </button>
                    </Link>
                  )}
                  {a < elem.startTime ? (
                    <div className="greyButton">
                      <img className="img-fluid mr-2" src="\landing\timer.png" alt="" />
                      <Countdown
                        date={a < elem.startTime ? new Date(parseInt(elem?.startTime) * 1000) : ""}
                        renderer={({ days, hours, minutes, seconds, completed }) => {
                          if (completed) {
                            window.location.reload();
                            return null;
                          }

                          // If days are greater than 0, display "Starts in XX days"
                          if (days > 0) {
                            return (
                              <h5 className="card-heading-4">
                                {`Starts in ${days.toString().padStart(2, "0")} day${
                                  days > 1 ? "s" : ""
                                }`}
                              </h5>
                            );
                          }

                          // Otherwise, display hours, minutes, and seconds like 07:30:12
                          return (
                            <h5 className="card-heading-4">
                              {`Starts in ${hours.toString().padStart(2, "0")}:${minutes
                                .toString()
                                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
                            </h5>
                          );
                        }}
                      ></Countdown>
                    </div>
                  ) : a < elem.endTime ? (
                    <div className="greyButton">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM14.375 10.625H10C9.83424 10.625 9.67527 10.5592 9.55806 10.4419C9.44085 10.3247 9.375 10.1658 9.375 10V5.625C9.375 5.45924 9.44085 5.30027 9.55806 5.18306C9.67527 5.06585 9.83424 5 10 5C10.1658 5 10.3247 5.06585 10.4419 5.18306C10.5592 5.30027 10.625 5.45924 10.625 5.625V9.375H14.375C14.5408 9.375 14.6997 9.44085 14.8169 9.55806C14.9342 9.67527 15 9.83424 15 10C15 10.1658 14.9342 10.3247 14.8169 10.4419C14.6997 10.5592 14.5408 10.625 14.375 10.625Z"
                          fill="#AFAFAF"
                        />
                      </svg>
                      <Countdown
                        date={a < elem.endTime ? new Date(parseInt(elem?.endTime) * 1000) : ""}
                        renderer={({ days, hours, minutes, seconds, completed }) => {
                          if (completed) {
                            window.location.reload();
                            return null;
                          }

                          // If days are greater than 0, display "Ends in XX days"
                          if (days > 0) {
                            return (
                              <h5 className="card-heading-4">
                                {`Ends in ${days.toString().padStart(2, "0")} day${
                                  days > 1 ? "s" : ""
                                }`}
                              </h5>
                            );
                          }

                          // Otherwise, display hours, minutes, and seconds like 07:30:12
                          return (
                            <h5 className="card-heading-4">
                              {`Ends in ${hours.toString().padStart(2, "0")}:${minutes
                                .toString()
                                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
                            </h5>
                          );
                        }}
                      ></Countdown>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="ptagggggs">
                <h3 className="innnerptag">{elem?.title}</h3>
              </div>
            </div>
            <div className="bottom_side">
              <div className="inner_text_bottom">
                <h2>For</h2>
                <p>
                  {elem?.proposalId > "21" ? parseInt(elem?.forVotes / 10 ** 18) : elem?.forVotes}
                </p>
              </div>
              <div className="inner_text_bottom">
                <h4>Against</h4>
                <p>
                  {elem?.proposalId > "21"
                    ? parseInt(elem?.againstVotes / 10 ** 18)
                    : elem?.againstVotes}
                </p>
              </div>
              <div className="inner_text_bottom">
                <h6>Abstain</h6>
                <p>
                  {elem?.proposalId > "21"
                    ? parseInt(elem?.abstainVotes / 10 ** 18)
                    : elem?.abstainVotes}
                </p>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  });

  const userNFt = () => {
    if (account) {
      const options = {
        method: "GET",
        url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.MintContract}`,
        headers: {
          accept: "application/json",
          "X-API-Key":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MTFmMDdiLWZkNGMtNDBmZS05MjQ0LWU5ZmNlOWVlNjY2MiIsIm9yZ0lkIjoiMjA5OTMxIiwidXNlcklkIjoiMjA5NjAzIiwidHlwZUlkIjoiNjgzMjA5NjgtZTEzNy00MTA1LWFjNDYtM2ZlNGRmNDViMGZlIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTY0OTAzNDEsImV4cCI6NDg3MjI1MDM0MX0.GR-teydWjr1g7RLcBh_x1QC-ECgy7Z6e-nTmGiE2qJ8",
        },
      };
      const a = axios.request(options).then(function (response) {
        return response.data.result;
      });
      return a.catch(function (error) {
        console.error(error);
      });
    }
  };

  const tomiprice = async () => {
    let b = await axios.get(
      "https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
    );
    // console.log("bbbbbbb", b?.data?.tominet?.usd);
    setTomiPrice(b?.data?.tominet?.usd);
  };

  const tomiTotalSupply = async () => {
    let b = await axios.get(
      "https://pro-api.coingecko.com/api/v3/coins/tominet?x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs"
    );
    let a = parseInt(b?.data?.market_data?.total_supply);
    let c = "";
    // console.log("bbbbbbb", a, c);
    if (a >= 1_000_000_000) {
      // If the number is greater than or equal to a billion
      c = (a / 1_000_000_000).toFixed(2) + "B"; // Convert to billions
    } else if (a >= 1_000_000) {
      // If the number is greater than or equal to a million but less than a billion
      c = (a / 1_000_000).toFixed(2) + "M"; // Convert to millions
    } else if (a >= 1_000) {
      // If the number is greater than or equal to a thousand but less than a million
      c = (a / 1_000).toFixed(2) + "K"; // Convert to thousands
    }
    // console.log("cccccc", c);

    setTomiSupply(c);
  };

  // console.log("userrrrr", userTomiBalance);
  useEffect(() => {
    getProposals();
    getProposalsData();
    Gata();
  }, [account, realPrice, UserWalleet]);

  useEffect(() => {
    tomiprice();
    tomiTotalSupply();
    Gata();
    getProposals();
    getProposalsData();
  }, [account, treeasure, UserWalleet, UserWalleet1, UserWalleet2, realPrice]);

  const closeBalModal = () => {
    window.$("#exampleModal999").modal("hide");
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);
  useEffect(() => {
    const isFirstVisit = localStorage.getItem("firstVisit");
    if (!isFirstVisit) {
      localStorage.setItem("firstVisit", "true");
      setTimeout(() => {
        handleShow11();
      }, 6000);
    }
  }, []);

  const [showwalletmobile, setShowWalletmobile] = useState(false);
  const [showwalletmobile11, setShowWalletmobile11] = useState(false);
  const handleClosewalletmobile = () => setShowWalletmobile(false);
  const handleClosewalletmobile11 = () => setShowWalletmobile11(false);
  const handleShowwalletmobile = () => {
    window.$("#exampleModalwalletmobile").modal("hide");
    setShowWalletmobile(true);
  };

  const { login, logout } = useAuth();

  //for mobile
  const connectMetaMask1 = async () => {
    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");
    if (account) {
      logout();
    } else {
      // login("injected");
      if (
        `${browserName}` === "Chrome" ||
        `${browserName}` === "Safari" ||
        `${browserName}` === "Opera"
      ) {
        handleShowwalletmobile();
      } else {
        login("injected");
        setShowWalletmobile11(false);
      }
      // window.$('#exampleModalwallet').modal('hide')
      // handleClosewalletmobile();
    }
  };

  const trustWallet = async () => {
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    // console.log(account, "account");
    if (account) {
      logout();
      localStorage.clear();
    } else {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      handleClosewalletmobile();
      // userRegister()
      // localStorage.setItem('connectorId', 'walletconnect');
      window.$("#exampleModalwallet1").modal("hide");
      // handleClose()
    }
  };

  const openM = () => {
    window.$("#exampleModalwallet1").modal("show");
  };

  const Signout = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    await logout(connectorId);
    window.$("#exampleModalwallet1").modal("hide");
    localStorage.clear();
    // if (Token) {
    //   localStorage.removeItem("AccessToken");
    //   history.push("/home");
    //   window.location.reload();
    //   toast.error("You have Logout", {
    //     position: "top-center",
    //     autoClose: 3000,
    //   });
    // }
  };

  return (
    <>
      <NavbarNew />
      <Navbar />
      <section className="banner-main ">
        <img src="\assets\bannerbg.png" alt="bg" className="pinkbg img-fluid " />

        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              {/* <div className="d-flex topTicket">
              <p>Treasury</p>
              <p>{UserWalleet.toFixed(3)} ETH</p>
            </div> */}
              <div className="pioneerDao">
                <div className="governancebox">
                  {/* <img
                    src="\logo-new.svg"
                    alt="img"
                    className="img-fluid logo-tomidao-mobile d-none"
                  /> */}

                  <h1 className="Htag">
                    Changing the <span className="futttture">future</span> <br />
                    of the internet
                  </h1>
                  {/* <p className="">
                  We are tomi pioneers and we aspire to build a better and brighter future <br />
                  for the Internet and we do it together, by common consent and in a democratic way
                </p> */}

                  <p className="">
                    Tomi aspire to build a better and brighter future <br />
                    for the Internet and we do it together, by common consent in a democratic way
                  </p>
                  <div className="twice-btns">
                    {/* <a style={{maxWidth: "200px", width: "100%", textAlign: "center"}} className="artdao-btn" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}> {isShown ? "Coming Soon" : "artDAO"}</a> */}

                    {/* <a style={{maxWidth: "200px", width: "100%", textAlign: "center"}} href="https://artdao.tomi.com/" target="_blank" rel="noreferrer" className="forum-btn btn-common">artDAO</a> */}
                    {/* <a style={{maxWidth: "200px", width: "100%", textAlign: "center"}} className="forum-btn btn-common" onMouseEnter={() => setIsShown1(true)} onMouseLeave={() => setIsShown1(false)}>{isShown1 ? "Coming Soon" : "Forum"}</a> */}

                    {/* <a style={{maxWidth: "200px", width: "100%", textAlign: "center"}} href="https://tomi.com/forumsignup" target="_blank" rel="noreferrer" className="forum-btn btn-common">Forum</a> */}
                  </div>
                  {/* {account ? (
                    <button className="connect-wallet-btn d-none" onClick={Signout}>
                      Disconnect
                    </button>
                  ) : (
                    <button className="connect-wallet-btn d-none" onClick={openM}>
                      Connect Wallet
                    </button>
                  )}
                  {account ? (
                    <div className="show-item mobile-show-pioneer d-none">
                      {userTomiBalance > 0 ? (
                        <>
                          <div className="dataa_top">
                            <div className="innerset mysett">
                              <div className="innersetleft">
                                <div className="innersetimg">
                                  <img src="\tomiicon.svg" alt="img" className="innerimg" />
                                </div>
                                <div className="lefttexts">
                                  <h4 className="lefthead">tomiNet</h4>
                                  <p className="leftpara">TOMI</p>
                                </div>
                              </div>
                              <div className="innersetright">
                                <h4 className="righthead">
                                  {userTomiBalance ? userTomiBalance?.toFixed(0) : "0"}
                                </h4>
                                <p className="rightpara">$14,000,000.54</p>
                              </div>
                            </div>
                            <div className="innerset">
                              <div className="innersetleft">
                                <div className="innersetimg">
                                  <img src="\tomiicon.svg" alt="img" className="innerimg" />
                                </div>
                                <div className="lefttexts">
                                  <h4 className="lefthead">Voting Power</h4>
                                  <p className="leftpara">For Voting Only</p>
                                </div>
                              </div>
                              <div className="innersetright">
                                <h4 className="righthead">
                                  {VoteAvail ? VoteAvail : <h4 className="righthead">No Vote</h4>}
                                </h4>
                                <h4 className="righthead">No Vote</h4>
                                <p className="rightpara">$ {(userTomiBalance * tomiPrice )}</p>
                              </div>
                            </div>
                          </div>

                          <div className="inner_navdataa">
                            <Link to="/editprofile">
                              <div className="data_outer">
                                <img src="\assets\editprofile.svg" alt="imgnav" />
                                <h2>Edit Profile</h2>
                              </div>
                            </Link>

                            <div onClick={Signout} className="data_outer">
                              <img src="\assets\disconnect.svg" alt="navimg" />
                              <h3>Disconnect</h3>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="dataa_top">
                            <div className="innerset mysett">
                              <div className="innersetleft">
                                <div className="innersetimg">
                                  <img src="\tomiicon.svg" alt="img" className="innerimg" />
                                </div>
                                <div className="lefttexts">
                                  <h4 className="lefthead">tomiNet</h4>
                                  <p className="leftpara">TOMI</p>
                                </div>
                              </div>
                              <div className="innersetright">
                                <h4 className="righthead">
                                  {userTomiBalance ? userTomiBalance?.toFixed(0) : 0}
                                </h4>
                                <p className="rightpara">
                                  $ {userTomiBalance ? (userTomiBalance * tomiPrice).toFixed(2) : 0}
                                </p>
                              </div>
                            </div>
                            <div className="innerset">
                              <div className="innersetleft">
                                <div className="innersetimg">
                                  <img src="\tomiicon.svg" alt="img" className="innerimg" />
                                </div>
                                <div className="lefttexts">
                                  <h4 className="lefthead">tomiNet</h4>
                                  <p className="leftpara">For Voting Only</p>
                                </div>
                              </div>
                              <div className="innersetright">
                                <h4 className="righthead">
                                  {userTomiBalance ? (
                                    userTomiBalance?.toFixed(0)
                                  ) : (
                                    <h4 className="righthead">No Vote</h4>
                                  )}
                                </h4>
                                <p className="rightpara">$ {(userTomiBalance * tomiPrice )}</p>
                              </div>
                            </div>
                          </div>

                          <div className="inner_navdataa">
                            <Link to="/editprofile">
                              <div className="data_outer">
                                <img src="\assets\editprofile.svg" alt="imgnav" />
                                <h2>Edit Profile</h2>
                              </div>
                            </Link>
                            <div onClick={Signout} className="data_outer">
                              <img src="\assets\disconnect.svg" alt="navimg" />
                              <h3>Disconnect</h3>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
                <div className="treasuryBox">
                  <div className="treasuryfirst">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="30"
                      viewBox="0 0 31 30"
                      fill="none"
                    >
                      <path
                        d="M4.25 27.5H26.75"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 22.5V13.75"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13 22.5V13.75"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18 22.5V13.75"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23 22.5V13.75"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.5 2.5L25.5 8.75H5.5L15.5 2.5Z"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                    {/* <span className="">
                    <p>Treasury</p>
                    <h4>{UserWalleet.toFixed(3)} ETH</h4>
                  </span> */}
                    <span>
                      <p className="smallheading">DAO Treasury</p>
                      <h3>
                        ~$
                        {/* EST Treasury Value $ */}
                        {res2 > 0
                          ? res2
                              ?.toFixed(2)
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                      </h3>
                      {/* <h6 className="mt-2 estimated-text">Estimated value of digital assets</h6> */}
                    </span>
                  </div>
                  <div className="treasuryfirst ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M18.75 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V25C5 25.663 5.26339 26.2989 5.73223 26.7678C6.20107 27.2366 6.83696 27.5 7.5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V8.75L18.75 2.5Z"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M17.5 2.5V7.5C17.5 8.16304 17.7634 8.79893 18.2322 9.26777C18.7011 9.73661 19.337 10 20 10H25"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                      <path
                        d="M12.5 11.25H10"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 16.25H10"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 21.25H10"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* <span className="">
                  <p>Treasury</p>
                  <h4>{UserWalleet.toFixed(3)} ETH</h4>
                </span> */}
                    <span>
                      <p className="smallheading">Total Proposals</p>
                      <h3>{proposalData ? proposalData.proposalsCount - 1 : "0"}</h3>
                    </span>
                  </div>
                  <div className="treasuryfirst ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="30"
                      viewBox="0 0 31 30"
                      fill="none"
                    >
                      <path
                        d="M11.75 15L14.25 17.5L19.25 12.5"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                      <path
                        d="M6.75 8.75C6.75 7.375 7.875 6.25 9.25 6.25H21.75C22.413 6.25 23.0489 6.51339 23.5178 6.98223C23.9866 7.45107 24.25 8.08696 24.25 8.75V23.75H6.75V8.75Z"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M28 23.75H3"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                    </svg>
                    {/* <span className="">
                  <p>Treasury</p>
                  <h4>{UserWalleet.toFixed(3)} ETH</h4>
                </span> */}
                    <span>
                      <p className="smallheading">Total Votes</p>
                      <h3>
                        {proposalData
                          ? new Intl.NumberFormat().format(
                              parseInt(proposalData.votesCount / 10 ** 18)
                            )
                          : "0"}
                      </h3>
                    </span>
                  </div>
                  <div className="treasuryfirst ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22.6123 12.9624C23.7939 13.4029 24.8454 14.1343 25.6695 15.0889C26.4936 16.0435 27.0636 17.1905 27.327 18.4237C27.5903 19.657 27.5384 20.9368 27.1761 22.1447C26.8138 23.3526 26.1527 24.4497 25.2541 25.3344C24.3554 26.2192 23.2482 26.863 22.0348 27.2064C20.8214 27.5499 19.5409 27.5818 18.3119 27.2992C17.0829 27.0167 15.945 26.4288 15.0033 25.5899C14.0617 24.751 13.3468 23.6883 12.9248 22.4999"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.75 7.5H10V12.5"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                      <path
                        d="M20.8873 17.3501L21.7623 18.2376L18.2373 21.7626"
                        stroke="#FF0083"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                    </svg>
                    <span>
                      <p className="smallheading">Total Supply</p>
                      <h3>
                        {tomiSupply} Tomi
                        {/* {(TotalMember).toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                      </h3>
                    </span>
                  </div>
                </div>
              </div>
              <div className="top_proposal_div">
                <div className="porposalDiv">
                  <div className="row ">
                    <div className="mydivphone">
                      <div className="">
                        <h2 className="porp">
                          Proposals
                        </h2>
                      </div>
                      <div className="">
                        {account ? (
                          userTomiBalance >= minReqNFtPro ? (
                            <div className="d-flex walletDiv align-items-center justify-content-md-end flex-wrap ">
                              {/* <h4 className="porps">
                          Hurray !!! 🥳🎉 You Have the Pioneers!
                          👋🇼‌🇪‌🇱‌🇨‌🇴‌🇲‌🇪‌🙋
                        </h4> */}
                              <button onClick={handleShow} className="pinkbtn">
                                Submit a Proposal
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex walletDiv align-items-end justify-content-md-end ">
                              <p className="text-danger">
                                To submit a proposal, you must have a min of{" "}
                                {minReqNFtPro.toLocaleString()} tomi Tokens in your wallet.
                              </p>
                            </div>
                          )
                        ) : (
                          <div className="d-flex walletDiv align-items-end justify-content-md-end ">
                            <p className="text-danger">Connect wallet to make a proposal.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="asjhdbvbas">{myProposal}</div>
                  {/* <div className="timeDiv ">
                                <div className="row">
                                    <div className="col-md-6 my-auto">
                                        <div className="noDiv">
                                            <p>137</p>
                                            <p>Founding Partners of NFT Seattle</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-md-end mt-md-0 mt-3 flex-wrap">


                                            <div className='greyButton'>
                                                <img className='img-fluid mr-2' src="\landing\timer.png" alt="" />
                                                <p>Expires in 14 hours</p>
                                            </div>
                                            <Link to='/executed'>    <button className='greyButtons border-0 bg-secondary '>Queued</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeDiv ">
                                <div className="row">
                                    <div className="col-md-6 my-auto">
                                        <div className="noDiv">
                                            <p>137</p>
                                            <p>Pioneers Almanac Second Edition</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-md-end mt-md-0 mt-3 flex-wrap">
                                            <button className='greyButtons border-0 bg-danger '>Defeated</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeDiv ">
                                <div className="row">
                                    <div className="col-md-6 my-auto">
                                        <div className="noDiv">
                                            <p>137</p>
                                            <p>Pioneers New York Fashion Week</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-md-end mt-md-0 mt-3 flex-wrap">

                                            <button className='greyButtons border-0 bg-info '>Executed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeDiv ">
                                <div className="row">
                                    <div className="col-md-6 my-auto">
                                        <div className="noDiv">
                                            <p>137</p>
                                            <p>Pioneers Almanac Second Edition</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-md-end mt-md-0 mt-3 flex-wrap">


                                            <div className='greyButton'>
                                                <img className='img-fluid mr-2' src="\landing\timer.png" alt="" />
                                                <p>Expires in 14 hours</p>
                                            </div>
                                            <button className='greyButtons border-0 bg-success '>Canceled</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal className="submit-proposal-modal" show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title>
              Submit Proposal{" "}
              <svg
                className="crossbutton_svvgg"
                onClick={handleClose}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M25.7081 24.2921C25.801 24.385 25.8747 24.4953 25.9249 24.6167C25.9752 24.7381 26.0011 24.8682 26.0011 24.9996C26.0011 25.131 25.9752 25.2611 25.9249 25.3825C25.8747 25.5039 25.801 25.6142 25.7081 25.7071C25.6151 25.8 25.5048 25.8737 25.3835 25.924C25.2621 25.9743 25.132 26.0001 25.0006 26.0001C24.8692 26.0001 24.7391 25.9743 24.6177 25.924C24.4963 25.8737 24.386 25.8 24.2931 25.7071L16.0006 17.4133L7.70806 25.7071C7.52042 25.8947 7.26592 26.0001 7.00056 26.0001C6.73519 26.0001 6.4807 25.8947 6.29306 25.7071C6.10542 25.5194 6 25.2649 6 24.9996C6 24.7342 6.10542 24.4797 6.29306 24.2921L14.5868 15.9996L6.29306 7.70708C6.10542 7.51944 6 7.26494 6 6.99958C6 6.73422 6.10542 6.47972 6.29306 6.29208C6.4807 6.10444 6.73519 5.99902 7.00056 5.99902C7.26592 5.99902 7.52042 6.10444 7.70806 6.29208L16.0006 14.5858L24.2931 6.29208C24.4807 6.10444 24.7352 5.99902 25.0006 5.99902C25.2659 5.99902 25.5204 6.10444 25.7081 6.29208C25.8957 6.47972 26.0011 6.73422 26.0011 6.99958C26.0011 7.26494 25.8957 7.51944 25.7081 7.70708L17.4143 15.9996L25.7081 24.2921Z"
                  fill="#0E1120"
                />
              </svg>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Link to="/simpleproposal">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 13H12"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 17H16"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                General Proposal
              </button>
            </Link>
            <Link to="/addfund">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.3009 7.91949V13.0695C19.3009 16.1495 17.5409 17.4695 14.9009 17.4695H6.11093C5.66093 17.4695 5.23093 17.4295 4.83093 17.3395C4.58093 17.2995 4.34094 17.2295 4.12094 17.1495C2.62094 16.5895 1.71094 15.2895 1.71094 13.0695V7.91949C1.71094 4.83949 3.47093 3.51953 6.11093 3.51953H14.9009C17.1409 3.51953 18.7509 4.46953 19.1809 6.63953C19.2509 7.03953 19.3009 7.44949 19.3009 7.91949Z"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.302 10.9196V16.0696C22.302 19.1496 20.542 20.4696 17.902 20.4696H9.11203C8.37203 20.4696 7.70204 20.3697 7.12204 20.1497C5.93204 19.7097 5.12203 18.7997 4.83203 17.3397C5.23203 17.4297 5.66203 17.4696 6.11203 17.4696H14.902C17.542 17.4696 19.302 16.1496 19.302 13.0696V7.91962C19.302 7.44962 19.262 7.02965 19.182 6.63965C21.082 7.03965 22.302 8.37962 22.302 10.9196Z"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4994 13.1394C11.9574 13.1394 13.1394 11.9574 13.1394 10.4994C13.1394 9.04136 11.9574 7.85938 10.4994 7.85938C9.04136 7.85938 7.85938 9.04136 7.85938 10.4994C7.85938 11.9574 9.04136 13.1394 10.4994 13.1394Z"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.78125 8.2998V12.6998"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.2227 8.2998V12.6998"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Funding Proposal
              </button>
            </Link>
            {/* /addcontraction */}
            <Link to="/addcontraction">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8.99953 13C8.33953 13.33 7.78953 13.82 7.37953 14.43C7.14953 14.78 7.14953 15.22 7.37953 15.57C7.78953 16.18 8.33953 16.67 8.99953 17"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.2109 13C15.8709 13.33 16.4209 13.82 16.8309 14.43C17.0609 14.78 17.0609 15.22 16.8309 15.57C16.4209 16.18 15.8709 16.67 15.2109 17"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.23047 8.01L21.4505 8"
                    stroke="#0E1120"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Technical Update Proposal
              </button>
            </Link>

            {/* <Link to="/">
              <button>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.15859 10.87C9.05859 10.86 8.93859 10.86 8.82859 10.87C6.44859 10.79 4.55859 8.84 4.55859 6.44C4.55859 3.99 6.53859 2 8.99859 2C11.4486 2 13.4386 3.99 13.4386 6.44C13.4286 8.84 11.5386 10.79 9.15859 10.87Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.4112 4C18.3512 4 19.9112 5.57 19.9112 7.5C19.9112 9.39 18.4113 10.93 16.5413 11C16.4613 10.99 16.3713 10.99 16.2812 11" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.15875 14.56C1.73875 16.18 1.73875 18.82 4.15875 20.43C6.90875 22.27 11.4188 22.27 14.1688 20.43C16.5888 18.81 16.5888 16.17 14.1688 14.56C11.4288 12.73 6.91875 12.73 4.15875 14.56Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                Committees Proposal
                <span className="comingsoon_text">Coming soon</span>
              </button>
            </Link> */}
            {/* /artdaoproposal */}
          </Modal.Body>
        </Modal>

        {/* buy pioneer modal ........................... */}

        {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  buy pioneer modal
</button> */}

        <div
          class="modal fade buy-pioneer-modal"
          id="exampleModal99"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img src="\add-circle.svg" alt="img" className="img-fluid" />
                  </span>
                </button>
              </div>
              <div class="modal-body">
                <h5>You don’t have Pionners to submit proposal</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                </p>
                <button className="buy-btn">
                  Buy Pioneer <img src="\export.svg" alt="img" className="img-fluid ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade buy-pioneer-modal11"
          id="exampleModal999"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  WELCOME
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img src="\add-circle.svg" alt="img" className="img-fluid" />
                  </span>
                </button>
              </div>
              <div class="modal-body">
                <img src="/money-bag.png" alt="img" />
                <h5>You have the Pioneer and Tomi Token</h5>
                <p>
                  Pioneer NFT : <span>{userTomiBalance ? userTomiBalance : ""}</span>
                </p>
                <p>
                  Tomi Token : <span>{userTomiBalance ? userTomiBalance?.toFixed(0) : ""}</span>
                </p>
                <button className="buy-btn" onClick={closeBalModal}>
                  {/* Buy Pioneer <img src="\export.svg" alt="img" className='img-fluid ml-2' /> */}
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
        {account ? (
          <>
            <div className="show-item desktop-show-pioneer anotherdiv">
              {userTomiBalance > 0 ? (
                <>
                  <div className="dataa_top">
                    <div className="innerset mysett">
                      <div className="innersetleft">
                        <div className="innersetimg">
                          <img src="\tomiicon.svg" alt="img" className="innerimg" />
                        </div>
                        <div className="lefttexts">
                          <h4 className="lefthead">tomiNet</h4>
                          <p className="leftpara">TOMI</p>
                        </div>
                      </div>
                      <div className="innersetright">
                        <h4 className="righthead">
                          {userTomiBalance ? userTomiBalance?.toFixed(0) : "0"}
                        </h4>
                        {/* <p className="rightpara">$14,000,000.54</p> */}
                      </div>
                    </div>
                    <div className="innerset">
                      <div className="innersetleft">
                        <div className="innersetimg">
                          <img src="\tomiicon.svg" alt="img" className="innerimg" />
                        </div>
                        <div className="lefttexts">
                          <h4 className="lefthead">Voting Power</h4>
                          <p className="leftpara">For Voting Only</p>
                        </div>
                      </div>
                      <div className="innersetright">
                        <h4 className="righthead">
                          {VoteAvail ? VoteAvail : <h4 className="righthead">No Vote</h4>}
                        </h4>
                        {/* <h4 className="righthead">No Vote</h4> */}
                        {/* <p className="rightpara">$ {(userTomiBalance * tomiPrice )}</p> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="inner_navdataa">
                    <Link to="/editprofile">
                      <div className="data_outer">
                        <img src="\assets\editprofile.svg" alt="imgnav" />
                        <h2>Edit Profile</h2>
                      </div>
                    </Link>

                    <div onClick={Signout} className="data_outer">
                      <img src="\assets\disconnect.svg" alt="navimg" />
                      <h3>Disconnect</h3>
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  <div className="dataa_top">
                    <div className="innerset mysett">
                      <div className="innersetleft">
                        <div className="innersetimg">
                          <img src="\tomiicon.svg" alt="img" className="innerimg" />
                        </div>
                        <div className="lefttexts">
                          <h4 className="lefthead">tomiNet</h4>
                          <p className="leftpara">TOMI</p>
                        </div>
                      </div>
                      <div className="innersetright">
                        <h4 className="righthead">
                          {userTomiBalance ? userTomiBalance?.toFixed(0) : 0}
                        </h4>
                        <p className="rightpara">
                          $ {userTomiBalance ? (userTomiBalance * tomiPrice).toFixed(2) : 0}
                        </p>
                      </div>
                    </div>
                    <div className="innerset">
                      <div className="innersetleft">
                        <div className="innersetimg">
                          <img src="\tomiicon.svg" alt="img" className="innerimg" />
                        </div>
                        <div className="lefttexts">
                          <h4 className="lefthead">tomiNet</h4>
                          <p className="leftpara">For Voting Only</p>
                        </div>
                      </div>
                      <div className="innersetright">
                        <h4 className="righthead">
                          {userTomiBalance ? (
                            userTomiBalance?.toFixed(0)
                          ) : (
                            <h4 className="righthead">No Vote</h4>
                          )}
                        </h4>
                        {/* <p className="rightpara">$ {(userTomiBalance * tomiPrice )}</p> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="inner_navdataa">
                    <Link to="/editprofile">
                      <div className="data_outer">
                        <img src="\assets\editprofile.svg" alt="imgnav" />
                        <h2>Edit Profile</h2>
                      </div>
                    </Link>
                    <div onClick={Signout} className="data_outer">
                      <img src="\assets\disconnect.svg" alt="navimg" />
                      <h3>Disconnect</h3>
                    </div>
                  </div> */}
                </>
              )}
            </div>
          </>
        ) : (
          ""
        )}
        {/* {`${browserName}` === 'Chrome' || `${browserName}` === 'Safari' || `${browserName}` === 'Opera' ?
        <>
          <div className='connectwalletmodal'>
            <div class="modal fade" id="exampleModalwalletmobile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">

                  <div class="modal-body">
                    <div className='main-heading'>
                      <h3>Confirm Transaction</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src='\landing\entypo_cross.png' className='img-fluid crossimg' />
                      </button>
                    </div>
                    <div className='Submitproposal'>
                      <div className="iconimages">
                        <div className="leftimage" onClick={handleShowwalletmobile}>
                          <img src="\landing\metamask 2.svg" className="img-fluid" />
                          <p>Metamask</p>
                        </div>
                        <div className="leftimage" onClick={trustWallet}>
                          <img src="\landing\Group.svg" className="img-fluid" />
                          <p>WalletConnect</p>
                        </div>
                      </div>

                    </div>
                    <div className='clear'>
                      <button>Clear WalletConnect Data</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div className="mobile-wallet">
            <Modal className="connectwallet-mobile" show={showwalletmobile11} onHide={handleClosewalletmobile11} centered>
              <Modal.Header closeButton>
                <Modal.Title>Connect Wallet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="connect-btn" onClick={connectMetaMask1}>
                  <div className="inner-content">
                    <img src="\landing\metamask-icon.svg" alt="img" className="img-fluid mr-2" />
                    <h6>Metamask</h6>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>

      } */}
        <div className="connectwalletmodal">
          <div
            class="modal fade"
            id="exampleModalwallet1"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div className="main-heading">
                    <h3>Connect Wallet</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M19.281 18.7198C19.3507 18.7895 19.406 18.8722 19.4437 18.9632C19.4814 19.0543 19.5008 19.1519 19.5008 19.2504C19.5008 19.349 19.4814 19.4465 19.4437 19.5376C19.406 19.6286 19.3507 19.7114 19.281 19.781C19.2114 19.8507 19.1286 19.906 19.0376 19.9437C18.9465 19.9814 18.849 20.0008 18.7504 20.0008C18.6519 20.0008 18.5543 19.9814 18.4632 19.9437C18.3722 19.906 18.2895 19.8507 18.2198 19.781L12.0004 13.5607L5.78104 19.781C5.64031 19.9218 5.44944 20.0008 5.25042 20.0008C5.05139 20.0008 4.86052 19.9218 4.71979 19.781C4.57906 19.6403 4.5 19.4494 4.5 19.2504C4.5 19.0514 4.57906 18.8605 4.71979 18.7198L10.9401 12.5004L4.71979 6.28104C4.57906 6.14031 4.5 5.94944 4.5 5.75042C4.5 5.55139 4.57906 5.36052 4.71979 5.21979C4.86052 5.07906 5.05139 5 5.25042 5C5.44944 5 5.64031 5.07906 5.78104 5.21979L12.0004 11.4401L18.2198 5.21979C18.3605 5.07906 18.5514 5 18.7504 5C18.9494 5 19.1403 5.07906 19.281 5.21979C19.4218 5.36052 19.5008 5.55139 19.5008 5.75042C19.5008 5.94944 19.4218 6.14031 19.281 6.28104L13.0607 12.5004L19.281 18.7198Z"
                          fill="#0E1120"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="Submitproposal">
                    <div className="iconimages">
                      {/* <div className="leftimage" onClick={connectMetaMask1}>
                      <img src="\landing\metamask 2.svg" className="img-fluid" />
                      <p>Metamask</p>
                    </div> */}

                      <div className="leftimage" onClick={trustWallet}>
                        <img src="\assets\wallet.svg" className="img-fluid" />
                        <p>WalletConnect</p>
                      </div>
                    </div>
                  </div>
                  <div className="clear">{/* <button>Clear WalletConnect Data</button> */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Modal className='categorymodal ' show={show11} onHide={handleClose11} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal__body">
            <img src="\modalpink.png" alt="img" className="img-fluid circleiiner" />
            <h5>Do you own a tomi Pioneer NFT?</h5>
            <p>Claim 15,000 tomi tokens per NFT now!</p>
            <h2>Claim your tokens by March 12, 2025!</h2>
            <a href="https://pioneersclaim.tomi.com/?_gl=1*qqr6d0*_gcl_au*ODY5MTQ1Njk4LjE3MzQzNTE3OTI."target="blank">
            <button>Claim Now</button>
            </a>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default Banner;
