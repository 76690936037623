import React, { useEffect } from 'react'
import "./termsofuse.scss"
import NavbarNew from '../NavbarNew/NavbarNew'
import Footer from '../footer/Footer'
const Termsofuse = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
     
            <section className='terms'>
            <NavbarNew />
                <img src='\assets\termbg.png' alt='img' className='img-fluid termpic' />
                <div className='custom-container'>
                    <div className='innercontent'>
                        <h6 className='grayspan'>Home <span>//</span>   <span className='pinkpara'>Terms of use</span></h6>
                        <p className='blackpara'>Terms of use</p>
                    </div>
                </div>
            </section>
            <section className='bottomtermofuse'>
                <img src='\assets\termshade.png' alt='img' className='img-fluid shade' />
                <div className='custom-container'>
                    <div className='bottominnercontent'>

                        <p className='mainpara'>
                            This Tomi User Agreement (the “Agreement”), entered into between yourself (“you” or the “User”) and tomi (the “Company”, “we” or “us”), together with any documents they expressly incorporate by reference, govern your access to and use of the Services (as defined below), including any content, functionality, and services offered on or through www.tomi.com (the “Site”).By using the Site or the services offered by the Company (whether through the Site or otherwise) and/or by creating an account in the Site, you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement, as well as our Privacy Policy and Cookie Policy.</p>
                        <h3 className='heading'>1. ELIGIBILITY.</h3>
                        <p className='para'>We supply, through our Site and/or our designated application, Digital Wallet (both, as defined below), decentralized domains naming system, browser and additional services (collectively, our “Services”) and products (collectively, our “Products”). The Company shall have a full discretion whether to provide or to deny, suspend or withhold access to Services to any particular user or group of users at any time and from time to time.</p>
                        <p className='para'>Please avoid using the Site and leave the Site immediately if you are not 18 years old or over, or if there is any legal restriction on using the Site or receiving Services applicable to yourself.</p>
                        <p className='para'>It is forbidden for users to visit the Site or use the Service through anonymous proxies and other services or technologies that hide the real internet connection of the user.</p>
                        <p className='para'>This Agreement shall be valid indefinitely.</p>
                        <p className='para'>If case of a contradiction between this Agreement and the terms, instructions, guidance and similar information found on the Site, the terms of this Agreement shall prevail.</p>
                        <p className='para'>THESE TERMS CONSTITUTE A LEGALLY BINDING AND VALID AGREEMENT AND SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE OR THE SERVICES. BY ACCESSING OR USING THE SITE OR THE SERVICES AND/OR BY USING ANY OF THE SERVICES CONTAINED THEREIN, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). IF YOU DO NOT AGREE WITH ANY OR ALL OF THE PROVISIONS OF THESE TERMS, YOU ARE PROHIBITED FROM ACCESSING, USING, OR TRANSACTING ON THE SITE OR THE SERVICES AND YOU ARE REQUESTED TO LEAVE THE SITE IMMEDIATELY. YOU FURTHER REPRESENT AND WARRANT THAT YOU ARE OTHERWISE LEGALLY PERMITTED TO USE THE SITE AND THE SERVICES IN YOUR JURISDICTION AND THAT THE COMPANY IS NOT LIABLE FOR YOUR COMPLIANCE WITH SUCH APPLICABLE LAWS.</p>


                        <h3 className='heading'>2. THE SERVICES/PRODUCTS</h3>
                        <p className='parahead'>2.1. Digital wallet Services.

                        </p>
                        <p className='para'>We may provide you with a digital wallet services (the “Digital Wallet” and the “Digital Wallet Services”, respectively). The Digital Wallet will enable you to receive, store, send and perform additional actions, all with respect to digital currencies. In addition, you may withdraw funds contained in your Digital Wallet into your bank account, and in such case the funds will be automatically exchanged into fiat currency and transferred into your account. The terms contained in this Agreement shall govern all uses in the Digital Wallet.

                        </p>
                        <p className='parahead'>2.1.1

                        </p>
                        <p className='para'>Funds may be transferred to your Digital Wallet from third party wallets or accounts, or be withdrawed into your Digital Wallet from your account in the Site, and sent from your Digital Wallet to external digital wallets. Such funds may only be in digital currencies supported by the Digital Wallet. It is your responsibility to check prior to any transfer of funds whether the Digital Wallet and/or any third party digital wallet supports the digital currencies you wish to transfer. The Digital Wallet will show the funds transferred into it once it was received into the Digital Wallet.

                        </p>
                        <p className='parahead'>2.1.2

                        </p>
                        <p className='para'>In order to sale, transfer, buy, convert or exchange any digital currencies please comply with the relevant instructions on the Site. Any such transaction shall be carried out promptly upon receipt of the relevant order, provided, however, that extended processing times or delays may occur, inter alia, due to technical issues or otherwise. By instructing the execution of a transaction, you agree and authorize to perform the transaction under the terms set forth in the website (subject to a reasonable deviation which may occur due to changes in the exchange rate or Third Party Fees as set forth below.

                        </p>
                        <p className='parahead'>2.1.3

                        </p>
                        <p className='para'>Please note that we are not liable for any currency which can be traded, sold, purchased, exchanged, converted or otherwise available at the Site or the Digital Wallet. Such currencies may be affected by third parties and market conditions, and we cannot guarantee the volatility or reliability level of such currencies. Prior to conducting any transaction, please be sure to check and research each currency you trade, use, transact, purchase or hold, as it may be volatile, be subject to certain limitations or restrictions or be part of fraudulent activity or a scam. We may also refuse to process or cancel any pending transaction as required by law, regulation or any court or any other governmental authority applicable to our activities, for instance, if there is suspicion of money laundering, terrorist financing, fraud, or any other financial crime.

                        </p>
                        <p className='parahead'>2.1.4

                        </p>
                        <p className='para'>Any order or instruction made by you shall be final and binding in accordance with its terms and may not be revoked or cancelled, unless otherwise specified in the order’s terms. In addition, any such order may not be processed if the balance contained in your Digital Wallet is insufficient to the completion of the transaction (including the payment of any fees to the Company or to third parties, such as gas fees).

                        </p>
                        <p className='parahead'>2.1.5

                        </p>
                        <p className='para'>In addition, you hereby agree and authorize the Company, at its sole discretion, to cancel, not approve, or to refuse to process any transaction, inter alia, due to legal restrictions or requirements under applicable law. The Company may also require, before the approval of any transaction, as a condition for the approval and processing of any transaction, to require the conduct of examinations for the purposes of preventing fraud, money laundering, terrorist financing and other financial crimes, and as required by applicable law. In the event a transaction could not be completed, please try to re-attempt to initiate the transaction (please note that the rates and fees applicable to the new transaction will be the then current rates and fees).

                        </p>
                        <p className='parahead'>2.1.6

                        </p>
                        <p className='para'>Any transaction conveyed from your Digital Wallet shall be deemed to be known to and approved by you. The Company shall not be held liable or responsible for any orders made from your Digital Wallet but not known to or authorized by you.

                        </p>
                        <p className='parahead'>2.1.7

                        </p>
                        <p className='para'>You may purchase digital currencies supported by the Digital Wallet by linking a valid payment method to your Digital Wallet. You authorise the Company to debit funds using your selected payment method(s) to complete your purchase.

                        </p>
                        <p className='parahead'>2.1.8

                        </p>
                        <p className='para'>Certain actions, including (but not limited to) purchase, sale, exchange and trade of digital currencies shall require the payment of a certain fee (the “Transaction Fees”). Such Transaction Fees shall be brought to your attention and approval prior to any applicable transaction. The Transaction Fees amount may be changed, at the Company’s sole discretion, from time to time without prior notice. By instructing the execution of a transaction, you agree and authorize the payment of any Transaction Fees related to such transaction.

                        </p>
                        <p className='parahead'>2.1.9

                        </p>
                        <p className='para'>In addition, certain actions, including (but not limited to) purchase, sale, exchange and trade of digital currencies shall require the payment of a fees imposed by third parties, such as the networks or blockchain on which the transactions are made (the “Third Party Fees”). Such Third Party Fees shall be brought to your attention and approval prior to any applicable transaction and shall be determined by quotes given by the relevant third party and may change at any time. By instructing the execution of a transaction, you agree and authorize the payment of any Third Party Fees related to such transaction.

                        </p>
                        <p className='parahead'>2.1.10

                        </p>
                        <p className='para'>Sale, purchase exchange and/or conversion of digital currencies are subject to the conversion rate applicable to the sold, purchased exchanged or converted currencies. Such exchange rate may change frequently, and all transactions shall be made at the spot exchange rate applicable to such transaction. By authorizing a transaction, you agree to the exchange rate, as shall be determined by the Site, based on quotes available on the market.

                        </p>
                        <p className='parahead'>2.1.11

                        </p>
                        <p className='para'>Please note that the Digital Wallet and the Digital Wallet Services only support specific currencies which may change from time to time. The currently supported currencies are the currencies available on the Digital Wallet. Please confirm that Digital Wallet supports the currency you intend to transact with before the transaction, and avoid transacting in the Digital Wallet with any currency not supported. Please note that we may terminate our support on any currency at any time at our sole discretion, without any prior notice, although under normal circumstances we will attempt to provide our customers with a 7 days’ notice, which shall be available at our website, prior to termination of support in any currency. Termination of support in currencies may also occur due to third party’s (such as the network, blockchain or the issuer of the currency) actions, which we do not have control over. If notice of termination of support is provided, please convert or withdraw from your wallet any terminating currencies prior to the termination of support on such currencies.

                        </p>
                        <p className='parahead'>2.1.12

                        </p>
                        <p className='para'>Please also note that certain digital currencies are only support by particular network(s) or blockchain(s). Transacting with such currencies via non-supporting network(s) or blockchain(s) may result in a permanent and irrevocable lose of the funds or currencies transacted with. It is your responsibility to check that the currencies you intend to transact with are supported by the network(s) or blockchain(s) you intend to use prior to the conduct of any transaction.

                        </p>
                        <p className='parahead'>2.1.13

                        </p>
                        <p className='para'>We may also offer you an option to stake certain currencies, either on our own behalf or for third parties, in consideration for a certain fee. If you shall agree to accept a staking offer, you shall be rewarded for transactions actually validated with the use of the funds staked by you, in an amount determined by the protocols of the applicable validated network. Such reward shall be subject to the payment of commission to us in a percentage which will be determined and made available to you on our Site. Please note that if you shall provide an instruction of staking, the funds you choose to stake may be locked, and the availability thereof shall be restricted, for certain periods of time, as stated by the applicable network protocol. Please note that your right for a reward for staking is subject to the actual receipt of such reward by us.

                        </p>
                        <p className='para'>2.2. Pioneers Club

                        </p>
                        <p className='parahead'>2.2.1

                        </p>
                        <p className='para'>We offer to provide a membership in our club entitled the “Pioneers”. Subscription to the Pioneers club will be made by purchasing a membership from the Site, or purchasing the Pioneers club member card from in the secondary market.

                        </p>
                        <p className='parahead'>2.2.2

                        </p>
                        <p className='para'>We will offer club memberships at a limited number to be determined by us at a minting event which will dates will be announced on the Site. We do not guarantee that all applications for purchase of memberships shall be respected. In addition, after the minting event, one Pioneers club membership will be auctioned every day and sold to the highest bidder.

                        </p>
                        <p className='parahead'>2.2.3

                        </p>
                        <p className='para'>Pioneers club membership will entitle its holder to a membership in our exclusive Pioneers club, represented by an NFT. Pioneer club members may also receive, at our sole discretion, certain gifts, such as the benefits specified in the Site (please note that as some of the benefit require additional development and set up times, some benefits will be granted only a certain period following the minting event). We may also grant the Pioneers club members additional awards and benefits from time to time not specified in the Site at our sole discretion. Among others, the purchasers of a Pioneers club membership (in the primary market) will be granted with a certain amount of our tokens entitled “tomi” (“TOMI tokens”). TOMI Tokens may be purchased from various cryptocurrency exchanges. The purchase and holding of TOMI Tokens shall be made at your sole discretion. Please note that the TOMI Tokens are strictly utility tokens intended to serve as the native token for the tomi ecosystem and can be used to facilitate payments on the tomiNet, to pay fees on the tomi products and to vote on the different decentralized autonomous organizations related to the tomi ecosystem. We specifically note that TOMI Tokens are not and should not be deemed or viewed as an investment in the tomi project.

                        </p>
                        <p className='parahead'>2.2.4</p>
                        <p className='para'>We specifically note that by purchasing a Pioneer club membership, you purchase digital products which entitles you to join our community and to all benefits mentioned on our Site, but you shall not be considered, in any matter, an investor, shareholder or otherwise an interest holder in tomi or in any company that operates tomi or that holds rights in the intellectual property underlying our project. We value our community and know that the community is crucial for tomi’s success, and therefore we will listen and consider offers raised by the community. However, tomi was fully funded by the private funds of its founders. As such, tomi’s management will have the sole and complete discretion to manage its business in the best way it deems correct. By purchasing a tomi Pioneers club membership, you waive any claims to tomi or to other third parties regarding your right to influence or vote on any managerial decisions (including tomi’s use of funds) (other than, of course, the tomi DAO, which will be managed by its members).

                        </p>
                        <p className='parahead'>2.2.5

                        </p>
                        <p className='para'>No refunds are permitted except with respect to any statutory warranties or guaranties that cannot be excluded or limited by law.

                        </p>
                        <p className='parahead'>2.2.6</p>
                        <p className='para'>Please note that the founding team will be granted with Pioneer club memberships.

                        </p>
                        <p className='parahead'>2.2.7</p>
                        <p className='para'>Whitelist</p>
                        <p className='parahead'>2.2.7.1</p>
                        <p className='para'>Since Pioneers club memberships are available up to a limited mystery cap we cannot assure that all demand for Pioneers club memberships will be met. We therefore created the whitelist, which intends to ensure that all early subscribers will have a right to purchase memberships by giving them a priority in the minting event.

                        </p>
                        <p className='parahead'>2.2.7.2

                        </p>
                        <p className='para'>Each whitelist subscriber will choose the amount of Pioneers club membership(s) will have priority in purchasing Pioneers. Specific instructions on how to receive the Pioneers subscribed to as part of the Whitelist stage will be sent to the subscribers in the email specified by them in their registration process.

                        </p>
                        <p className='parahead'>2.2.7.3</p>
                        <p className='para'>If a whitelist subscriber have not paid the Pioneer purchase price in full, the applicable subscriber must complete the payment up to 24 hours prior to public minting, and not later than January 14, 2023. Any Pioneer not paid for in full prior to the abovementioned date will be sold as part of the public minting phase, and the upfront payment paid on account of the subscription will not be refunded.
                        </p>
                        <p className='parahead'>2.2.7.4</p>
                        <p className='para'>Please note that the whitelist registration (including the number of memberships you subscribe for) is binding and non-reversible.

                        </p>
                        <p className='parahead'>2.2.8</p>
                        <p className='para'>Initial Public Minting

                        </p>
                        <p className='parahead'>2.2.8.1

                        </p>
                        <p className='para'>Any Pioneer not subscribed for by whitelist subscribers (but not more than 1,500 Pioneers) will be sold to the public at a minting event on a date to be announced (the “Minting Event”).

                        </p>
                        <p className='parahead'>2.2.8.2

                        </p>
                        <p className='para'>The sale of Pioneers to purchasers from the public shall be made in the Minting Event, in consideration for a purchase price to be set by us at our discretion.

                        </p>
                        <p className='parahead'>2.2.8.3

                        </p>
                        <p className='para'>The allocation of Pioneers to purchasers shall be on a “first come first serve” basis. Notwithstanding, the number of Pioneers that can be purchased by any single wallet is no more than 10 Pioneers per wallet.

                        </p>
                        <p className='parahead'>2.2.9</p>
                        <p className='para'>Daily Pioneer Auction

                        </p>
                        <p className='parahead'>2.2.9.1

                        </p>
                        <p className='para'>Pioneers may be sold at daily auctions, the initial amounts of Pioneers to be auctioned upon and the number of tokens to be issued to the highest bidder(s) in the daily auctions shall be determined by us, but please note that since tomi is a decentralized project, the governing community, through the tomiDAO, may change the auctions’ mechanism, standards, number of Pioneers to be auctioned upon, number of tokens to be issued and other benefits to be awarded to the winning bidders and any other criteria, at its sole discretion.

                        </p>
                        {/* <p className='para'>Eros dictum ante class sagittis dolor suspendisse pretium. Rutrum amet penatibus velit amet arcu sociosqu vivamus id.</p>
                        <ul>
                            <li>Accounts<span> - Viverra habitant nisl aptent neque faucibus.</span></li>
                            <li>Fee on Tomi <span>- Integer quisque nisl fermentum mi at convallis quisque elementum.</span></li>
                            <li>Transactions <span>- Sem tortor netus etiam viverra posuere netus donec suspendisse.</span></li>
                            <li>Programs Derived Address<span> - Metus aptent facilisi nullam nibh ante pharetra tortor.</span></li>
                            <li>Cross Program Invocation<span>- Himenaeos vitae natoque class; elit malesuada maximus fringilla congue dictum scelerisque.</span></li>
                        </ul>
                        <h3 className='heading'>
                            Aut beatae nihil ex odit laudantium
                        </h3>
                        <p className='para'>At praesent consectetur elit etiam quam vitae. Morbi litora dictum iaculis enim lectus pretium. Dis hendrerit cras platea facilisis a rutrum. Tristique ligula vivamus habitasse imperdiet fringilla dapibus. Aliquet cursus convallis nisi rhoncus tortor neque. Placerat praesent maecenas; primis et eu tincidunt mattis. Aenean dis luctus magnis; potenti augue amet velit.</p>
                        <p className='para1'>
                            usto molestie orci habitant vehicula sodales phasellus. <span>Scelerisque ridiculus</span> fusce nisi in platea. Sem conubia arcu eleifend aptent adipiscing.</p> */}
                    </div>
                </div>

            </section>
          <Footer/>
        </>
    )
}

export default Termsofuse