import React, { useState, useEffect } from "react";
import "./submitporposal.scss";
import axios from "axios";
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";
import UserProposalInteraction from "../../hooks/dataSenders/userpropseContrct";
import TextEditor from "./TextEditor";
import AmountApprove from "../../hooks/dataSenders/approve";
import {
  useTomiBalance,
  useNftUsed,
  UserTreasure,
  UserTreasureEth,
  UserTreasureUsd,
  TomiRealPrice,
  CheckAllowance,
  UserWalletBalance,
  useBaseFeeGet,
  useMinProReq,
} from "../../hooks/useBalances";
import Loader from "../../hooks/loader";
import { Link } from "react-router-dom";
import DoaAbi from "../../utils/DoaAbi.json";
// import MintDappAbi from '../../utils/tomiMintingDaap.json'
import PioneerNft from "../../utils/pioneerNftDao.json";
import TomiAuctionAbi from "../../utils/tomiAuctionAbi.json";
import TomiMainAbi from "../../utils/tomiMainDaoAbi.json";
import TomiDevFunds from "../../utils/tomiDevFundAbi.json";
import Environment from "../../utils/Environment";
// import TomiTokenAbi from '../../utils/tomiTokenDao.json'
import TomiTokenAbi from "../../utils/TomiTokenAbi.json";
import { toast } from "react-toastify";

function AddContraction() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const tomiAddress = Environment.TomiTokenDao;
  const web3 = useWeb3();
  const tomiPioneerNft = Environment.PioneerNftDao;
  const tomiAuction = Environment.TomiAuctionDao;
  const tomiMainDao = Environment.TomiMainDao;
  const tomiDevFund = Environment.TomiDevFunds;
  const UserWalleet = UserTreasure();
  const UserWalleet1 = UserTreasureEth();
  const UserWalleet2 = UserTreasureUsd();
  const [ethConvert, setEthConvert] = useState([]);
  const { account } = useWeb3React();
  const proposalBaseFee = useBaseFeeGet();
  const minReqNFtPro = useMinProReq();
  const [functionId, setFunctionId] = useState("");
  const [treeasure, setTreeasure] = useState(0);
  const [treeasureEth, setTreeasureEth] = useState(0);
  const [treeasureUst, setTreeasureUst] = useState(0);
  const [userNftNo, setUserNftNo] = useState(0);
  const [userEthBalance, setUserEthBalance] = useState();
  const [newArr, setNewArr] = useState([]);
  const [userTomiBalance, setUserTomiBalance] = useState(0);
  const { CheckTomiPrice } = TomiRealPrice();
  const [realPrice, setRealPrice] = useState(0);
  const [userTreasuryNft, setUserTreasuryNft] = useState("");
  const [abiFun, setAbiFun] = useState([]);
  const [contractAbi, setContractAbi] = useState([]);
  const [filterdata, setFilterdata] = useState([]);
  const [mainLoader, setMainLoader] = useState(false);
  const [userAllNft, setUserAllNft] = useState([]);
  const [allowance, setAllowance] = useState();
  const { CheckNftUsed } = useNftUsed();
  const { UserProposeContrct } = UserProposalInteraction();
  const { UserApprove } = AmountApprove();
  const { CheckUserBalance } = useTomiBalance();
  const { fetchBalance } = UserWalletBalance();
  const { CheckAllow } = CheckAllowance();
  // console.log("usd balance", userBalance)
  const [Clickdrop1, setClickDrop1] = useState("");
  const [contactAddress, setContactAddress] = useState();
  const [quorumCategory, setQuorumCategory] = useState();
  const [inputList, setInputList] = useState([
    {
      recipient: "",
      amount: "",
    },
  ]);
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    sendFunds: false,
    sendTransaction: false,
    length: 0,
  });
  let [inputsContract, setInputsContract] = useState([]);

  const setEditor = (data) => {
    // console.log("we get here is ", data)
    setInputs({ ...inputs, description: data, length: data.length - 8 });
  };

  // console.log("e::::::::::::: ", userEthBalance, ethusd)

  // console.log("e::::::::::;;kllll;:::NFT ", userAllNft, realPrice, minReqNFtPro);

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  //contract interaction input
  const handleChange = (e, index, name) => {
    // console.log("handelllll", name);
    let temp = inputsContract;
    if (name === "afterAuctionBuyer" || name === "afterAuctionFutureTeam") {
      let convertedvalue = web3.utils.toWei(e.target.value.toString(), "ether");
      temp[index] = convertedvalue;
      setInputsContract(temp);
      // setInputsContract(value)
    } else if (e.target.value === "true" || e.target.value === "false") {
      // temp.push(e.target.value)
      temp[index] = e.target.value;
      setInputsContract(temp);
    }
    // temp[name] = e.target.value
    // // let value = e.target.value
    // setInputsContract(temp)
    // console.log("eeee", temp)
    // setInputsContract([(inputsContract => ({...inputsContract, parseInt([value]:value}))]);
    // console.log(temp, 'temp');
  };

  const handleChange11 = (e, index) => {
    let a = "";
    if (index === "changeTimeBuffer") {
      a = e.target.value * 60;
    } else {
      a = e.target.value;
    }
    // console.log("nameeee", a);
    setInputsContract(a);
    // console.log("eeee", typeof e.target.value);
  };

  const handleChangeQourum = (e, index) => {
    let a = "";
    if (index === "changeTimeBuffer") {
      a = e.target.value * 60;
    } else {
      a = e.target.value;
    }

    // console.log("nameeee", a);
    setInputsContract((inputsContract) => ({ ...inputsContract, value: a }));
  };

  const handleInputChangeAmount = (e) => {
    const { name, value } = e.target;
    setInputsContract((inputsContract) => ({ ...inputsContract, [name]: value }));
  };

  const userNFt = () => {
    const options = {
      method: "GET",
      url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.MintContract}`,
      headers: {
        accept: "application/json",
        "X-API-Key": "skMp4F6qEu9nqR6jnLUnBW7F1PfEZkptx6kTbh5CoLBE6zD4Twv5KPCI48HGICQM",
      },
    };

    const a = axios.request(options).then(async function (response) {
      console.log(response.data.result);
      let dummArr = [];
      // for (let i = 0; i < response?.data?.total; i++) {
      //   let ab = await CheckNftUsed(response?.data?.result[i].token_id)
      //   if (ab === false) {
      //     dummArr.push(response?.data?.result[i])
      //   }
      // }
      let promise = await Promise.all(
        response?.data?.result.map((data) => {
          return CheckNftUsed(data?.token_id);
        })
      );
      let a = 0;
      for (let i = 0; i < promise?.length; i++) {
        if (promise[i] === false && a < minReqNFtPro) {
          a = a + 1;
          dummArr.push(response?.data?.result[i]?.token_id);
        }
      }
      setUserAllNft(dummArr);
      setUserNftNo(response.data.result.length);
      return response.data.result;
    });
    return a.catch(function (error) {
      console.error(error);
    });
  };

  //for abi function
  const newdumm = () => {
    // console.log("herereere");
    if (contactAddress === tomiPioneerNft) {
      // console.log("dapp")
      let dumArr = [];
      let dumArr2 = [];
      for (let i = 0; i < PioneerNft.length; i++) {
        // console.log("jereree", PioneerNft[i])
        if (PioneerNft[i].type === "function") {
          if (PioneerNft[i].stateMutability === "nonpayable") {
            if (
              PioneerNft[i].name === "proposeSendNFT" ||
              PioneerNft[i].name === "proposeBlockNFTWallet"
            ) {
              dumArr.push(PioneerNft[i]);
              dumArr2.push(PioneerNft[i].name);
              setNewArr(dumArr);
              setAbiFun(dumArr2);
            }
          }
        }
      }
    } else if (contactAddress === tomiAddress) {
      // console.log(":::::::", TomiTokenAbi);
      let dumArr = [];
      let dumArr2 = [];
      for (let i = 0; i < TomiTokenAbi.length; i++) {
        if (TomiTokenAbi[i].type === "function") {
          // console.log("jereree", TomiTokenAbi[i]);
          if (TomiTokenAbi[i].stateMutability === "nonpayable") {
            // console.log("jereree", TomiTokenAbi[i]);
            if (
              // TomiTokenAbi[i].name === "updateEmissions" ||
              // TomiTokenAbi[i].name === "fundDao" ||
              // TomiTokenAbi[i].name === "updateMarketingWallet" ||
              TomiTokenAbi[i].name === "updateCoreTeamWallet"
              // TomiTokenAbi[i].name === "updateFutureTeamWallet"
            ) {
              dumArr.push(TomiTokenAbi[i]);
              dumArr2.push(TomiTokenAbi[i].name);
              setNewArr(dumArr);
              setAbiFun(dumArr2);
            }
          }
        }
      }
      // console.log("mint", dumArr2)
    } else if (contactAddress === tomiAuction) {
      let dumArr = [];
      let dumArr2 = [
        "changeMinimumBidIncreasePercentage",
        "changeReservePrice(Starting Bid)",
        "changeTimeBuffer",
      ];
      for (let i = 0; i < TomiAuctionAbi.length; i++) {
        if (TomiAuctionAbi[i].type === "function") {
          if (TomiAuctionAbi[i].stateMutability === "payable") {
            // if (TomiAuctionAbi[i].name === 'updateEmissions' || TomiAuctionAbi[i].name === 'updateMarketingWallet' || TomiAuctionAbi[i].name === 'updateTomiWallet' || TomiAuctionAbi[i].name === 'changeBlockState') {
            // console.log("jereree", TomiAuctionAbi[i])
            dumArr.push(TomiAuctionAbi[i]);
            // dumArr2.push(TomiAuctionAbi[i].name)
            setNewArr(dumArr);
            setAbiFun(dumArr2);
            // }
          }
        }
      }
      // console.log("mint", dumArr2)
    } else if (contactAddress === tomiMainDao) {
      let dumArr = [];
      let dumArr2 = ["setProposalMinAmountTokens", "setQuorumPercentage", "setProposalFee"];
      for (let i = 0; i < TomiMainAbi.length; i++) {
        if (TomiMainAbi[i].type === "function") {
          if (TomiMainAbi[i].stateMutability === "nonpayable") {
            // if (TomiMainAbi[i].name === 'updateEmissions' || TomiMainAbi[i].name === 'updateMarketingWallet' || TomiMainAbi[i].name === 'updateTomiWallet' || TomiMainAbi[i].name === 'changeBlockState') {
            // console.log("jereree", TomiMainAbi[i])
            dumArr.push(TomiMainAbi[i]);
            // dumArr2.push(TomiAuctionAbi[i].name)
            setNewArr(dumArr);
            setAbiFun(dumArr2);
            // }
          }
        }
      }
      // console.log("mint", dumArr2)
    } else if (contactAddress === tomiDevFund) {
      let dumArr = [];
      let dumArr2 = ["SendToken"];
      for (let i = 0; i < TomiDevFunds.length; i++) {
        if (TomiDevFunds[i].type === "function") {
          if (TomiDevFunds[i].stateMutability === "payable") {
            // if (TomiDevFunds[i].name === 'updateEmissions' || TomiDevFunds[i].name === 'updateMarketingWallet' || TomiDevFunds[i].name === 'updateTomiWallet' || TomiDevFunds[i].name === 'changeBlockState') {
            // console.log("jereree", TomiDevFunds[i])
            dumArr.push(TomiDevFunds[i]);
            // dumArr2.push(TomiAuctionAbi[i].name)
            setNewArr(dumArr);
            setAbiFun(dumArr2);
            // }
          }
        }
      }
      // console.log("mint", dumArr2)
    }
  };
  // pionerr nft input
  const handleInputChange = (e) => {
    const result = web3.utils.isAddress(e.target.value);
    // console.log("result", result)
    const { name, value } = e.target;
    const list = inputsContract;
    list[name] = value;
    list.error = result;
    setInputsContract(list);
  };

  const filterData = (e) => {
    setClickDrop1(e);
    // console.log("eeeeeeee:::::::???????", e)
    if (e === "updateEmissions") {
      setFunctionId("1");
    } else if (e === "updateMarketingWallet") {
      setFunctionId("2");
    } else if (e === "updateCoreTeamWallet") {
      setFunctionId("3");
    } else if (e === "updateFutureTeamWallet") {
      setFunctionId("4");
    } else if (e === "proposeSendNFT") {
      setFunctionId("5");
    } else if (e === "proposeBlockNFTWallet") {
      setFunctionId("6");
    } else if (e === "changeMinimumBidIncreasePercentage") {
      setFunctionId("9");
    } else if (e === "changeReservePrice(Starting Bid)") {
      setFunctionId("10");
    } else if (e === "changeTimeBuffer") {
      setFunctionId("11");
    } else if (e === "ChangeNoOfNFtToSubmitProposal") {
      setFunctionId("12");
    } else if (e === "ChangeQuorum") {
      setFunctionId("13");
    } else if (e === "ChangeNftRequiredToVote") {
      setFunctionId("14");
    } else if (e === "ChangeFeeToSubmitProposal") {
      setFunctionId("15");
    } else if (e === "SendToken") {
      setFunctionId("20");
    }
    if (
      e === "updateCoreTeamWallet" ||
      e === "updateFutureTeamWallet" ||
      e === "updateMarketingWallet"
    ) {
      const result = newArr.filter((ab) => e === ab.name);
      setFilterdata(result);
    } else {
      const result = newArr.filter((ab) => e === ab.name);
      const newResult = result[0].inputs[0].components.filter(
        (ab) =>
          ab.name === "afterAuctionBuyer" ||
          ab.name === "afterAuctionFutureTeam" ||
          ab.name === "mintAllowed"
      );
      // console.log("resulttt",newResult)
      // if(result[0].inputs === 'afterAuctionBuyer' || result[0].inputs === 'afterAuctionFutureTeam' || result[0].inputs === 'mintAllowed'){
      setFilterdata(newResult);
    }
  };

  // treasury nft
  const GetTreasuryNft = () => {
    const options = {
      method: "GET",
      url: `https://deep-index.moralis.io/api/v2/${Environment.Treasury}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.MintContract}`,
      headers: {
        accept: "application/json",
        "X-API-Key": "skMp4F6qEu9nqR6jnLUnBW7F1PfEZkptx6kTbh5CoLBE6zD4Twv5KPCI48HGICQM",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.result);
        setUserTreasuryNft(response.data.result);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const gata = async () => {
    // let res1 = await CheckUserNft(account);
    // let res = await CheckBalanceUsdt();/
    GetTreasuryNft();
    // const res11 = await userNFt();
    let balance = await CheckUserBalance();
    setUserTomiBalance(balance);
    let res2 = await fetchBalance();
    let allowed = await CheckAllow(account, Environment.CreateProposal);
    // setUserNftNo(res11?.length);
    setAllowance(allowed);
    // setEthUsd(res);
    setUserEthBalance(res2);
    // let price = await CheckTomiPrice();
    // setRealPrice(price)
    let res21 = await UserWalleet;
    setTreeasure(res21);
    let res3 = await UserWalleet1;
    setTreeasureEth(res3);
    let res4 = await UserWalleet2;
    setTreeasureUst(res4);
    // console.log("setUserEthBalance", res2)
    // let res = await CheckAvailableApprove(account);
    // setUserBalance(res11)
  };

  const getContractAddress = () => {
    let a = "";
    if (contactAddress === tomiPioneerNft) {
      a = JSON.stringify(PioneerNft);
      setContractAbi(a);
    } else if (contactAddress === tomiAddress) {
      a = JSON.stringify(TomiTokenAbi);
      setContractAbi(a);
    } else if (contactAddress === tomiAuction) {
      a = JSON.stringify(TomiAuctionAbi);
      setContractAbi(a);
    } else if (contactAddress === tomiMainDao) {
      a = JSON.stringify(TomiMainAbi);
      setContractAbi(a);
    } else if (contactAddress === tomiDevFund) {
      a = JSON.stringify(TomiDevFunds);
      setContractAbi(a);
    }
  };
  // console.log("asdfasdfasfasdasdfasdfsa", inputsContract);
  // Approve Amount fee
  const Approve = async () => {
    try {
      setMainLoader(true);
      let res = await UserApprove(account, contactAddress);
      if (res) {
        setMainLoader(false);
        // window.$("#exampleModal11").modal("show");
        let allowed = await CheckAllow(account, Environment.CreateProposal);
        setAllowance(allowed);

        try {
          setMainLoader(true);

          // let res1 = await UserVolume(account)
          // console.log("proposal res", res1.length)
          // signatures.push(res1.toString())
          const res = await UserProposeContrct(
            // target,
            // values,
            // signatures,
            inputs.description.toString(),
            inputs.title,
            // contractaddresses,s
            Clickdrop1,
            // proposedFrom,
            functionId,
            inputsContract,
            contactAddress,
            account,
            quorumCategory
          );
          setTimeout(() => {
            setMainLoader(false);
            window.$("#exampleModal11").modal("hide");
            window.$("#exampleModal1").modal("show");
          }, "10000");

          // setMainLoader(false);
          // await toast.success("Proposal Cancelled Successfully");
        } catch (error) {
          setMainLoader(false);
          window.$("#exampleModal11").modal("hide");
          let a = error.message.slice(21, 70);
          toast.error(a);
          console.log("eerrr", error.message.code);
          // await setError(error.message)
          // setMainLoader(false);
        }
      } else {
        setMainLoader(false);
        window.$("#exampleModal11").modal("show");
      }
      // console.log("ressssss", res);
    } catch (err) {
      setMainLoader(false);
      console.log("eerrrr", err);
    }
  };

  useEffect(() => {
    if (
      DoaAbi.length > 0 ||
      TomiTokenAbi.length > 0 ||
      TomiMainAbi.length > 0 ||
      TomiDevFunds.length > 0
    ) {
      newdumm();
      getContractAddress();
    }
  }, [contactAddress, account, DoaAbi, TomiTokenAbi, TomiMainAbi, TomiDevFunds]);

  useEffect(() => {
    gata();
  }, []);

  useEffect(() => {
    if (account) {
      axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD").then((res) => {
        setEthConvert(res.data.USD);
      });

      axios
        .get(
          "https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
        )
        .then((res) => {
          setRealPrice(res?.data?.tominet?.usd);
          // setEthUsd(res.data.USD);
        });
    }
    gata();
  }, [account, contactAddress]);

  //modal sucess failure
  const opeenModal = (e) => {
    if (userTomiBalance >= proposalBaseFee / realPrice) {
      window.$("#exampleModal").modal("show");
    } else {
      window.$("#exampleModal2").modal("show");
    }
  };

  // Modal opening function
  const successs = () => {
    window.$("#exampleModal1").modal("hide");
    window.location.assign("/home");
    // history.push('/home')
  };
  const successs1 = () => {
    window.$("#exampleModal2").modal("hide");
  };

  //submit proposal
  const submitProposal = async () => {
    if (account) {
      if (allowance < proposalBaseFee / realPrice) {
        Approve();
      } else {
        try {
          setMainLoader(true);
          const res = await UserProposeContrct(
            inputs.description.toString(),
            inputs.title,
            Clickdrop1,
            inputsContract,
            contactAddress,
            account,
            quorumCategory
          );
          setTimeout(() => {
            setMainLoader(false);
            window.$("#exampleModal11").modal("hide");
            window.$("#exampleModal1").modal("show");
          }, "10000");
        } catch (error) {
          setMainLoader(false);
          window.$("#exampleModal11").modal("hide");
          console.log("eerrr", error.code);
          if (error.code == "INVALID_ARGUMENT") {
            toast.error("Enter Value in Whole Number");
          } else {
            let a = error.message.slice(22, 79);
            toast.error(a);
          }
        }
      }
    } else {
      setMainLoader(false);
      toast.error("Connect Your Wallet");
    }
  };

  return (
    <>
      {mainLoader && <Loader />}
      <section className="main-submit ">
        <img src="\assets\neww.png" alt="img" className="proposalbgimg" />
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              <div className="row">
                <div className="col-lg-9 m-auto p-0">
                  <div className="innersubmit">
                    <div className="main-heading">
                      <h2>Submit Proposal</h2>
                      <div className="simple_proposal_content">
                        <div className="active-content">
                          <Link to="/simpleproposal">
                            <button className="">
                              <svg
                                className="svggbuttonss"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 13H12"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 17H16"
                                  stroke="#0E1120"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              General Proposal
                            </button>
                          </Link>
                          <Link to="/addfund">
                            <button>
                              <svg
                                className="svggbuttonss"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <path
                                  d="M14.4737 6.43962V10.3021C14.4737 12.6121 13.1538 13.6021 11.1738 13.6021H4.58125C4.24375 13.6021 3.92125 13.5722 3.62125 13.5047C3.43375 13.4747 3.25375 13.4222 3.08875 13.3622C1.96375 12.9422 1.28125 11.9671 1.28125 10.3021V6.43962C1.28125 4.12962 2.60125 3.13965 4.58125 3.13965H11.1738C12.8538 3.13965 14.0613 3.85214 14.3838 5.47964C14.4363 5.77964 14.4737 6.08712 14.4737 6.43962Z"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M16.7236 8.68947V12.552C16.7236 14.862 15.4036 15.852 13.4236 15.852H6.83109C6.27609 15.852 5.7736 15.777 5.3386 15.612C4.4461 15.282 3.83859 14.5995 3.62109 13.5045C3.92109 13.572 4.24359 13.602 4.58109 13.602H11.1736C13.1536 13.602 14.4736 12.612 14.4736 10.302V6.43947C14.4736 6.08697 14.4436 5.77199 14.3836 5.47949C15.8086 5.77949 16.7236 6.78447 16.7236 8.68947Z"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.87064 10.3546C8.96416 10.3546 9.85065 9.46807 9.85065 8.37454C9.85065 7.28102 8.96416 6.39453 7.87064 6.39453C6.77711 6.39453 5.89062 7.28102 5.89062 8.37454C5.89062 9.46807 6.77711 10.3546 7.87064 10.3546Z"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M3.58203 6.72461V10.0246"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.1641 6.72461V10.0246"
                                  stroke="#222D37"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Funding Proposal
                            </button>
                          </Link>
                          <Link to="/addcontraction">
                            <button className="active-btn-show">
                              <svg
                                className="svggbuttonss"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <path
                                  d="M6.75063 10.25C6.25563 10.4975 5.84313 10.865 5.53563 11.3225C5.36312 11.585 5.36312 11.915 5.53563 12.1775C5.84313 12.635 6.25563 13.0025 6.75063 13.25"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.4062 10.25C11.9012 10.4975 12.3137 10.865 12.6212 11.3225C12.7937 11.585 12.7937 11.915 12.6212 12.1775C12.3137 12.635 11.9012 13.0025 11.4062 13.25"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V7.25C16.5 3.5 15 2 11.25 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17Z"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.67188 6.5075L16.0869 6.5"
                                  stroke="#222D37"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Technical Update Proposal
                            </button>
                          </Link>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="title"
                            placeholder="Enter title"
                            onChange={handleChange1}
                          />
                          <div className="textareadescription">
                            <div className="form-group">
                              {/* <label>Description</label> */}
                              <TextEditor
                                onEditorChange={setEditor}
                                placeholder="Enter proposal discription"
                              />
                              {inputs.length >= 10 ? (
                                ""
                              ) : (
                                <p className="error">Minimum 10 Characters required </p>
                              )}
                            </div>
                            <div className="main-inputs">
                              {/* <div className='upperhead  topmt'>
                      <h3 className='head'>Contract Interaction</h3>
                      <div class="custom-control custom-checkbox">
                        {!inputs.sendFunds ?
                          <>
                            <input type="checkbox" class="custom-control-input" id="customCheck2" onChange={(e) => setInputs(inputs => ({ ...inputs, sendTransaction: !inputs.sendTransaction }))} />
                            <label class="custom-control-label" for="customCheck2"></label>
                          </>
                          : ''}
                      </div>
                    </div> */}
                              <div>
                                <div className="inputmain">
                                  <label>Select Contract Address</label>
                                  <div class="dropdown buttn">
                                    <button
                                      class="btn dropbtn"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {contactAddress ? (
                                        <span className="text-dark set-sm-text">
                                          {contactAddress}
                                        </span>
                                      ) : (
                                        <span>Contract Addresss</span>
                                      )}
                                      <img src="\arrow-down.svg" className="imgbtn" />
                                    </button>
                                    {/* {inputs.contractAddress ? */}
                                    <div
                                      class="dropdown-menu dropmain1"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      {/* <a
                                class="dropdown-item item1"
                                onClick={() => {
                                  setContactAddress(tomiAddress);
                                }}
                              >
                                Tomi Token
                              </a> */}
                                      {/* <a
                                class="dropdown-item item1"
                                onClick={() => {
                                  setContactAddress(tomiPioneerNft);
                                }}
                              >
                                Pioneer NFT
                              </a> */}
                                      <a
                                        class="dropdown-item item1"
                                        onClick={() => {
                                          setContactAddress(tomiMainDao);
                                        }}
                                      >
                                        Tomi Dao
                                      </a>
                                      {/* <a
                                class="dropdown-item item1"
                                onClick={() => {
                                  setContactAddress(tomiDevFund);
                                }}
                              >
                                Tomi Dev Funds
                              </a> */}
                                      {/* <p class="item1">Tomi Auction (Comming Soon)</p> */}
                                      {/* <p>Tomi Dev Funds (Comming Soon)</p> */}
                                      {/* <a class="dropdown-item item1" onClick={() => { setContactAddress(tomiAuction) }}>Tomi Auction (Comming Soon)</a>
                            <a class="dropdown-item item1" onClick={() => { setContactAddress(tomiDevFund) }}>Tomi Dev Funds (Comming Soon)</a> */}
                                      {/* <a class="dropdown-item item1" onClick={() => { setContactAddress('') }}>TDNS</a> */}
                                      {/* <a class="dropdown-item item1" onClick={() => { setContactAddress('0xDB8e9d97D000c5922B678E4660E44e84375d3099') }}>Minting Daap</a> */}
                                      {/* <a class="dropdown-item item1" onClick={() => { setContactAddress('') }}>Tomi Web</a> */}
                                    </div>
                                    {/* : ''} */}
                                  </div>
                                </div>
                                <div className="inputmain">
                                  <p>ABI</p>
                                  <div className="unlimited">
                                    <p>{contactAddress ? contractAbi : ""}</p>
                                  </div>
                                  <div class="dropdown buttn">
                                    <button
                                      class="btn dropbtn"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {Clickdrop1 ? (
                                        <span className="text-dark">{Clickdrop1}</span>
                                      ) : (
                                        <span>Select Function</span>
                                      )}
                                      <img src="\arrow-down.svg" className="imgbtn" />
                                    </button>
                                    {contactAddress ? (
                                      <div
                                        class="dropdown-menu dropmain"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        {abiFun.map((elem) => {
                                          return (
                                            <a
                                              class="dropdown-item item1"
                                              onClick={() => {
                                                filterData(elem);
                                              }}
                                            >
                                              {elem}
                                            </a>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {Clickdrop1 === "proposeSendNFT" ? (
                                  <>
                                    <div className="inputmain">
                                      <p>Recipient Address To Send NFT</p>
                                      <input
                                        type="text"
                                        name="recipient"
                                        placeholder="Recipient Address To Send NFT"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      {inputList.error === false ? (
                                        <p className="error">Invalid ETH Address</p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="inputmain">
                                      {/* <p>NFT ID </p>
                           <input type="text" name='NFTid' placeholder='NFT ID which is to send' value={amount} onChange={e => handleInputChangeAmount(e)} /> */}
                                      <div class="dropdown buttn">
                                        <label>Treasury Pioneer Nft Id</label>
                                        <button
                                          class="btn dropbtn"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {inputsContract?.elem ? (
                                            <span className="text-dark">
                                              {" "}
                                              {inputsContract?.elem.token_id}{" "}
                                            </span>
                                          ) : (
                                            "Select NFT ID to Send"
                                          )}
                                          <img src="\arrow-down.svg" className="imgbtn" />
                                        </button>
                                        <div
                                          class="dropdown-menu dropmain1"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {userTreasuryNft?.map((elem) => {
                                            return (
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    elem,
                                                  }));
                                                }}
                                              >
                                                {elem?.token_id}
                                              </a>
                                            );
                                          })}
                                        </div>
                                        {/* : ''} */}
                                      </div>
                                    </div>
                                  </>
                                ) : Clickdrop1 === "proposeBlockNFTWallet" ? (
                                  <>
                                    <div className="inputmain">
                                      <p>Recipient Address To Block</p>
                                      <input
                                        type="text"
                                        name="recipient"
                                        placeholder="Recipient Address  "
                                        onChange={(e) => handleChange11(e)}
                                      />
                                      {inputList.error === false ? (
                                        <p className="error">Invalid ETH Address</p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : Clickdrop1 === "changeMinimumBidIncreasePercentage" ||
                                  Clickdrop1 === "changeReservePrice(Starting Bid)" ||
                                  Clickdrop1 === "changeTimeBuffer" ? (
                                  <>
                                    <div className="inputmain">
                                      <p>{`Enter ${Clickdrop1}`}</p>
                                      <input
                                        type="number"
                                        name="recipient"
                                        placeholder={
                                          Clickdrop1 === "changeTimeBuffer"
                                            ? "Enter Time in Minutes"
                                            : "Enter Value"
                                        }
                                        onChange={(e) => handleChange11(e, Clickdrop1)}
                                      />
                                    </div>
                                  </>
                                ) : Clickdrop1 === "setProposalMinAmountTokens" ||
                                  Clickdrop1 === "setProposalFee" ? (
                                  <>
                                    <div className="inputmain">
                                      <p>{`Enter ${Clickdrop1} (Usd)`}</p>
                                      <input
                                        type="number"
                                        name="recipient"
                                        placeholder="Enter Value"
                                        onChange={(e) => handleChange11(e)}
                                      />
                                    </div>
                                  </>
                                ) : Clickdrop1 === "setQuorumPercentage" ? (
                                  <>
                                    <div className="inputmain">
                                      <label>Select Catagory To Change Quorum</label>
                                      <div class="dropdown buttn">
                                        <button
                                          class="btn dropbtn"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {quorumCategory ? (
                                            <span className="text-dark set-sm-text">
                                              {quorumCategory}
                                            </span>
                                          ) : (
                                            <span>Select Category</span>
                                          )}
                                          <img src="\arrow-down.svg" className="imgbtn" />
                                        </button>
                                        {/* {inputs.contractAddress ? */}
                                        <div
                                          class="dropdown-menu dropmain1"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {/* <a
                                    class="dropdown-item item1"
                                    onClick={() => {
                                      setQuorumCategory("Tomi Token");
                                    }}
                                  >
                                    Tomi Token
                                  </a> */}
                                          {/* <a
                                    class="dropdown-item item1"
                                    onClick={() => {
                                      setQuorumCategory("Funds");
                                    }}
                                  >
                                    Funds
                                  </a> */}
                                          {/* <a
                                    class="dropdown-item item1"
                                    onClick={() => {
                                      setQuorumCategory("Pioneer NFT");
                                    }}
                                  >
                                    Pioneer NFT
                                  </a> */}
                                          <a
                                            class="dropdown-item item1"
                                            onClick={() => {
                                              setQuorumCategory("DAO");
                                            }}
                                          >
                                            Tomi Dao
                                          </a>
                                        </div>
                                        {/* : ''} */}
                                      </div>
                                    </div>

                                    {quorumCategory === "Tomi Token" ? (
                                      <>
                                        <div className="inputmain">
                                          <label>
                                            Select SubCatagory of Tomi Token Governance To Change
                                            Quorum
                                          </label>
                                          <div class="dropdown buttn">
                                            <button
                                              class="btn dropbtn"
                                              type="button"
                                              id="dropdownMenuButton11"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              {inputsContract?.Quorum ? (
                                                <span className="text-dark set-sm-text">
                                                  {inputsContract?.Quorum}
                                                </span>
                                              ) : (
                                                <span>Select SubCategory</span>
                                              )}
                                              <img src="\arrow-down.svg" className="imgbtn" />
                                            </button>
                                            {/* {inputs.contractAddress ? */}
                                            <div
                                              class="dropdown-menu dropmain1"
                                              aria-labelledby="dropdownMenuButton12"
                                            >
                                              {/* <a
                                        class="dropdown-item item1"
                                        onClick={() => {
                                          setInputsContract((inputsContract) => ({
                                            ...inputsContract,
                                            Quorum: "Emission",
                                          }));
                                        }}
                                      >
                                        Change Emission
                                      </a> */}
                                              {/* <a
                                        class="dropdown-item item1"
                                        onClick={() => {
                                          setInputsContract((inputsContract) => ({
                                            ...inputsContract,
                                            Quorum: "CoreTeam",
                                          }));
                                        }}
                                      >
                                        update Core Team Wallet
                                      </a> */}
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "FutureTeam",
                                                  }));
                                                }}
                                              >
                                                update Tomi Team Wallet
                                              </a>
                                              {/* <a
                                        class="dropdown-item item1"
                                        onClick={() => {
                                          setInputsContract((inputsContract) => ({
                                            ...inputsContract,
                                            Quorum: "Marketing",
                                          }));
                                        }}
                                      >
                                        update Marketing Wallet
                                      </a> */}
                                            </div>
                                            {/* : ''} */}
                                            {inputsContract?.Quorum && (
                                              <div className="inputmain">
                                                <p>{`Enter ${Clickdrop1}`}</p>
                                                <input
                                                  type="number"
                                                  name="recipient"
                                                  placeholder="Enter Value In Percentage"
                                                  onChange={(e) => handleChangeQourum(e)}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : quorumCategory === "Funds" ? (
                                      <>
                                        <div className="inputmain">
                                          <label>
                                            Select SubCategory of Funds To Change Quorum
                                          </label>
                                          <div class="dropdown buttn">
                                            <button
                                              class="btn dropbtn"
                                              type="button"
                                              id="dropdownMenuButton12"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              {inputsContract?.Quorum ? (
                                                <span className="text-dark set-sm-text">
                                                  {inputsContract?.Quorum}
                                                </span>
                                              ) : (
                                                <span>Select SubCategory</span>
                                              )}
                                              <img src="\arrow-down.svg" className="imgbtn" />
                                            </button>
                                            {/* {inputs.contractAddress ? */}
                                            <div
                                              class="dropdown-menu dropmain1"
                                              aria-labelledby="dropdownMenuButton12"
                                            >
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "ETH",
                                                  }));
                                                }}
                                              >
                                                ETH
                                              </a>
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "Tomi Token",
                                                  }));
                                                }}
                                              >
                                                Tomi Token
                                              </a>
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "USDT",
                                                  }));
                                                }}
                                              >
                                                USDT
                                              </a>
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "1155",
                                                  }));
                                                }}
                                              >
                                                ERC1155
                                              </a>
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "721",
                                                  }));
                                                }}
                                              >
                                                ERC721
                                              </a>
                                            </div>
                                            {inputsContract?.Quorum === "ETH" ||
                                            inputsContract?.Quorum === "Tomi Token" ||
                                            inputsContract?.Quorum === "USDT" ? (
                                              <div
                                                class="dropdown-menu dropmain1"
                                                aria-labelledby="dropdownMenuButton13"
                                              >
                                                <button
                                                  class="btn dropbtn"
                                                  type="button"
                                                  id="dropdownMenuButton13"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {inputsContract?.fundAmount ? (
                                                    <span className="text-dark set-sm-text">
                                                      {inputsContract?.fundAmount}
                                                    </span>
                                                  ) : (
                                                    <span>Select SubCategory</span>
                                                  )}
                                                  <img src="\arrow-down.svg" className="imgbtn" />
                                                </button>
                                                <a
                                                  class="dropdown-item item1"
                                                  onClick={() => {
                                                    setInputsContract((inputsContract) => ({
                                                      ...inputsContract,
                                                      fundAmount: "ETH",
                                                    }));
                                                  }}
                                                >
                                                  Up to $100,000 (or equivalent)
                                                </a>
                                                <a
                                                  class="dropdown-item item1"
                                                  onClick={() => {
                                                    setInputsContract((inputsContract) => ({
                                                      ...inputsContract,
                                                      fundAmount: "Tomi Token",
                                                    }));
                                                  }}
                                                >
                                                  Between $100,000 and $500,000(or equivalent)
                                                </a>
                                                <a
                                                  class="dropdown-item item1"
                                                  onClick={() => {
                                                    setInputsContract((inputsContract) => ({
                                                      ...inputsContract,
                                                      fundAmount: "USDT",
                                                    }));
                                                  }}
                                                >
                                                  Above $500,000
                                                </a>
                                              </div>
                                            ) : (
                                              <>
                                                {inputsContract?.Quorum && (
                                                  <div className="inputmain">
                                                    <p>{`Enter ${Clickdrop1}`}</p>
                                                    <input
                                                      type="number"
                                                      name="recipient"
                                                      placeholder="Enter Value In Percentage"
                                                      onChange={(e) => handleChangeQourum(e)}
                                                    />
                                                  </div>
                                                )}
                                              </>
                                            )}

                                            {/* : ''} */}
                                            {/* {inputsContract?.Quorum && inputsContract?.fundAmount &&
                                                <div className='inputmain'>
                                                  <p>{`Enter ${Clickdrop1}`}</p>
                                                  <input type="text" name='recipient' placeholder='Enter Value' onChange={e => handleChangeQourum(e)} />
                                                </div>
                                              } */}
                                          </div>
                                        </div>
                                        {inputsContract?.Quorum === "ETH" ||
                                        inputsContract?.Quorum === "Tomi Token" ||
                                        inputsContract?.Quorum === "USDT" ? (
                                          <div className="inputmain">
                                            <label>
                                              Select SubCatagory of Funds According to Range
                                            </label>
                                            <div class="dropdown buttn">
                                              <button
                                                class="btn dropbtn"
                                                type="button"
                                                id="dropdownMenuButton12"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                {inputsContract?.fundAmount ? (
                                                  <span className="text-dark set-sm-text">
                                                    {inputsContract?.fundAmount}
                                                  </span>
                                                ) : (
                                                  <span>Select SubCategory</span>
                                                )}
                                                <img src="\arrow-down.svg" className="imgbtn" />
                                              </button>
                                              {/* {inputs.contractAddress ? */}

                                              <div
                                                class="dropdown-menu dropmain1"
                                                aria-labelledby="dropdownMenuButton13"
                                              >
                                                <a
                                                  class="dropdown-item item1"
                                                  onClick={() => {
                                                    setInputsContract((inputsContract) => ({
                                                      ...inputsContract,
                                                      fundAmount: "<100000",
                                                    }));
                                                  }}
                                                >
                                                  Up to $100,000 (or equivalent)
                                                </a>
                                                <a
                                                  class="dropdown-item item1"
                                                  onClick={() => {
                                                    setInputsContract((inputsContract) => ({
                                                      ...inputsContract,
                                                      fundAmount: "100000-500000",
                                                    }));
                                                  }}
                                                >
                                                  Between $100,000 and $500,000(or equivalent)
                                                </a>
                                                <a
                                                  class="dropdown-item item1"
                                                  onClick={() => {
                                                    setInputsContract((inputsContract) => ({
                                                      ...inputsContract,
                                                      fundAmount: "500000<",
                                                    }));
                                                  }}
                                                >
                                                  Above $500,000
                                                </a>
                                              </div>

                                              {/* : ''} */}
                                              {inputsContract?.Quorum &&
                                                inputsContract?.fundAmount && (
                                                  <div className="inputmain">
                                                    <p>{`Enter ${Clickdrop1}`}</p>
                                                    <input
                                                      type="text"
                                                      name="recipient"
                                                      placeholder="Enter Value"
                                                      onChange={(e) => handleChangeQourum(e)}
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : //  quorumCategory === "Pioneer NFT" ? (
                                    //   <>
                                    //     <div className="inputmain">
                                    //       {/* <label>Enter Value To Change Pioneer NFT Quorum</label> */}
                                    //       {/* {inputsContract?.Quorum && */}
                                    //       <div className="inputmain">
                                    //         <p>{`Enter Value To Change Pioneer NFT ${Clickdrop1}`}</p>
                                    //         <input
                                    //           type="text"
                                    //           name="recipient"
                                    //           placeholder="Enter Value"
                                    //           onChange={(e) => handleChangeQourum(e)}
                                    //         />
                                    //       </div>
                                    //       {/* } */}
                                    //     </div>
                                    //   </>
                                    // )
                                    quorumCategory === "DAO" ? (
                                      <>
                                        <div className="inputmain">
                                          <label>Select SubCatagory of Dao To Change Quorum</label>
                                          <div class="dropdown buttn">
                                            <button
                                              class="btn dropbtn"
                                              type="button"
                                              id="dropdownMenuButton12"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              {inputsContract?.Quorum ? (
                                                <span className="text-dark set-sm-text">
                                                  {inputsContract?.Quorum}
                                                </span>
                                              ) : (
                                                <span>Select SubCategory</span>
                                              )}
                                              <img src="\arrow-down.svg" className="imgbtn" />
                                            </button>
                                            {/* {inputs.contractAddress ? */}
                                            <div
                                              class="dropdown-menu dropmain1"
                                              aria-labelledby="dropdownMenuButton1"
                                            >
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "MinAmtTokenForProposal",
                                                  }));
                                                }}
                                              >
                                                {" "}
                                                Min Amount Token TO Submit Proposal
                                              </a>
                                              {/* <a
                                        class="dropdown-item item1"
                                        onClick={() => {
                                          setInputsContract((inputsContract) => ({
                                            ...inputsContract,
                                            Quorum: "MinAmtNFTForVote",
                                          }));
                                        }}
                                      >
                                        {" "}
                                        Min Amount NFT TO Vote
                                      </a> */}
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "proposalFee",
                                                  }));
                                                }}
                                              >
                                                Proposal Fee
                                              </a>
                                              <a
                                                class="dropdown-item item1"
                                                onClick={() => {
                                                  setInputsContract((inputsContract) => ({
                                                    ...inputsContract,
                                                    Quorum: "QuorumPercentage",
                                                  }));
                                                }}
                                              >
                                                Quorum Percentage
                                              </a>
                                            </div>
                                            {/* : ''} */}
                                            {inputsContract?.Quorum && (
                                              <div className="inputmain">
                                                <p>{`Enter ${Clickdrop1}`}</p>
                                                <input
                                                  type="number"
                                                  name="recipient"
                                                  placeholder="Enter Value"
                                                  onChange={(e) => handleChangeQourum(e)}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : Clickdrop1 === "SendToken" ? (
                                  <>
                                    <div className="main-inputs">
                                      <div className="inputmain">
                                        <div class="dropdown buttn">
                                          <label>Select Currency to Send</label>
                                          <button
                                            class="btn dropbtn"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            {inputsContract?.currencySend ? (
                                              <span className="text-dark">
                                                {" "}
                                                {inputsContract?.currencySend}{" "}
                                              </span>
                                            ) : (
                                              "Select Type of Fund Proposal"
                                            )}
                                            <img src="\arrow-down.svg" className="imgbtn" />
                                          </button>
                                          <div
                                            class="dropdown-menu dropmain1"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            <a
                                              class="dropdown-item item1"
                                              onClick={() => {
                                                setInputsContract((inputsContract) => ({
                                                  ...inputsContract,
                                                  currencySend: "Tomi Token",
                                                }));
                                              }}
                                            >
                                              Tomi Token
                                            </a>
                                            <a
                                              class="dropdown-item item1"
                                              onClick={() => {
                                                setInputsContract((inputsContract) => ({
                                                  ...inputsContract,
                                                  currencySend: "ETH",
                                                }));
                                              }}
                                            >
                                              ETH
                                            </a>
                                            <a
                                              class="dropdown-item item1"
                                              onClick={() => {
                                                setInputsContract((inputsContract) => ({
                                                  ...inputsContract,
                                                  currencySend: "USDT",
                                                }));
                                              }}
                                            >
                                              USDT
                                            </a>
                                            {/* <a class="dropdown-item item1" onClick={() => { setCurrencySend('ETH') }}>Eth</a>
                                        <a class="dropdown-item item1" onClick={() => { setCurrencySend('USDT') }}>USDT</a> */}
                                          </div>
                                          <div>
                                            {/* <div className='inputmain'>
                                              <p>{`Amount ${inputsContract?.currencySend ? inputsContract?.currencySend : ''}`} </p>
                                              <input type="text" name='amount' placeholder='Enter Amount' onChange={e => handleChange(e, 'amount')} />
                                            </div> */}
                                            <div>
                                              <div className="inputmain">
                                                <p>Recipient ETH Wallet Address</p>
                                                <input
                                                  type="text"
                                                  name="recipient"
                                                  placeholder="Enter Wallet Address for Recieving Funds"
                                                  onChange={(e) => handleInputChange(e)}
                                                />
                                                {inputsContract.error === false ? (
                                                  <p className="error">Invalid ETH Address</p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div className="inputmain">
                                                <p>
                                                  {`Amount ${
                                                    inputsContract?.currencySend
                                                      ? inputsContract?.currencySend
                                                      : ""
                                                  }`}{" "}
                                                </p>
                                                <input
                                                  type="text"
                                                  name="amount"
                                                  placeholder="Enter Amount"
                                                  onChange={(e) => handleInputChangeAmount(e)}
                                                />
                                              </div>
                                              {inputsContract?.currencySend === "Tomi Token" &&
                                              parseInt(inputsContract?.amount) > treeasure ? (
                                                <p className="error">
                                                  Amount Exceeded From Treasury
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {inputsContract?.currencySend === "ETH" &&
                                              inputsContract?.amount > treeasureEth ? (
                                                <p className="error">
                                                  Amount Exceeded From Treasury
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {inputsContract?.currencySend === "USDT" &&
                                              inputsContract?.amount > treeasureUst ? (
                                                <p className="error">
                                                  Amount Exceeded From Treasury
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              <p className="mb-3">
                                                {" "}
                                                Approx value: ${" "}
                                                {inputsContract?.currencySend === "Tomi Token"
                                                  ? inputsContract?.amount
                                                    ? parseFloat(
                                                        realPrice * inputsContract.amount
                                                      ).toFixed(2)
                                                    : "0"
                                                  : inputsContract?.currencySend === "ETH"
                                                  ? inputsContract?.amount
                                                    ? parseFloat(
                                                        ethConvert * inputsContract.amount
                                                      ).toFixed(2)
                                                    : "0"
                                                  : inputsContract?.currencySend === "USDT"
                                                  ? inputsContract?.amount
                                                    ? parseFloat(inputsContract.amount).toFixed(2)
                                                    : "0"
                                                  : "0"}
                                              </p>
                                            </div>
                                            {/* <p className='mb-3'> Approx value: $ {currencySend === 'Tomi Token' ? inputList?.amount ? parseFloat(1 * inputList.amount).toFixed(2) : '0' :
                                          currencySend === 'ETH' ? inputList?.amount ? parseFloat(ethConvert * inputList.amount).toFixed(2) : '0' :
                                            currencySend === 'USDT' ? inputList?.amount ? parseFloat(inputList.amount).toFixed(2) : '0' : '0'}</p> */}
                                          </div>
                                          {/* : ''} */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="inputmain">
                                    {filterdata[0]?.name === "afterAuctionBuyer" ? (
                                      <>
                                        {filterdata?.map((elem, index) => {
                                          // console.log("emisionnn", elem.components);
                                          return (
                                            <>
                                              <p>
                                                {elem?.name === "mintAllowed"
                                                  ? `Minting of Tomi Token Allowed or Not when pioneer Minted (true/ false)`
                                                  : elem.name === "afterAuctionBuyer"
                                                  ? "Amount of Tomi Token to be distributed to Buyer on Mint"
                                                  : elem?.name === "afterAuctionFutureTeam"
                                                  ? "Amount of Tomi Token to be distributed to Future Team Wallet on Mint"
                                                  : ""}
                                              </p>
                                              <input
                                                type="text"
                                                name="{elem.name}"
                                                key={index}
                                                placeholder={`${
                                                  elem?.name === "mintAllowed" ||
                                                  elem?.name === "miningAllowed"
                                                    ? "Please type True or False"
                                                    : elem?.name
                                                } `}
                                                onChange={(e) => handleChange(e, index, elem.name)}
                                              />
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {filterdata?.map((elem, index) => {
                                          // console.log("emisionnn11111", elem.components);
                                          return (
                                            <>
                                              <p>{elem?.name}</p>
                                              <input
                                                type="text"
                                                name="{elem.name}"
                                                key={index}
                                                placeholder={`Enter ${elem.name} Address`}
                                                onChange={(e) => handleChange11(e, index)}
                                              />
                                            </>
                                          );
                                        })}
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className="submitbutton">
                    <button className='aaa' onClick={opeenModal}>Submit Proposal</button>
                  </div> */}
                            <div className="submitbutton ">
                              {/* {{if(inputs.description && inputs.title){}else if(){}else{}}} */}
                              {inputs.length >= 10 &&
                              inputs.title !== "" &&
                              contactAddress &&
                              inputsContract !== "" &&
                              quorumCategory !== "" &&
                              Clickdrop1 ? (
                                userTomiBalance >= minReqNFtPro ? (
                                  <button className="aaa" onClick={opeenModal}>
                                    Submit Proposal
                                  </button>
                                ) : (
                                  <p className="error">You dont have {minReqNFtPro} Tokens</p>
                                )
                              ) : (
                                <p className="error">Fields Cannot be Empty</p>
                              )}
                              {/* <button className='aaa' onClick={opeenModal}>Submit Proposal</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='main-inputs'>
                    <div className='inputmain'>
                      <div class="dropdown buttn">
                        <label>Submit Proposal By</label>
                        <button class="btn dropbtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {proposedFrom ? <span className='text-dark'>  {proposedFrom} </span> : 'Select Method for Proposal Submit'}
                          <img src='\arrow-down.svg' className='imgbtn' />
                        </button>
                        <div class="dropdown-menu dropmain1" aria-labelledby="dropdownMenuButton1">
                          <a class="dropdown-item item1" onClick={() => { setProposedFrom('2') }}>Tomi Token</a>

                        </div>
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='main-inputs'>
                    <div className='inputmain'>
                      <div class="dropdown buttn">
                        <label>User Nft Id</label>
                        <button class="btn dropbtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {userSelectedNft ? <span className='text-dark'> {userSelectedNft} </span> : 'Select NFT for Proposal Submittion'}
                          <img src='\arrow-down.svg' className='imgbtn' />
                        </button>
                        <div class="dropdown-menu dropmain1" aria-labelledby="dropdownMenuButton1">
                          {userAllNft.map((elem) => {
                            return (
                              <a class="dropdown-item item1" onClick={() => { setUserSelectedNft(elem.token_id) }}>{elem.token_id}</a>
                            )
                          })}

                        </div>
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='enddate'>
                    <p>End Date</p>
                    <div className='imagess'>
                      <input type="date" name="endDate" placeholder='Select end date' onChange={handleChange1} />
                    </div>

                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Insufficient Modal */}
      <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Confirm Transaction</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg
                      class="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M19.281 18.7198C19.3507 18.7895 19.406 18.8722 19.4437 18.9632C19.4814 19.0543 19.5008 19.1519 19.5008 19.2504C19.5008 19.349 19.4814 19.4465 19.4437 19.5376C19.406 19.6286 19.3507 19.7114 19.281 19.781C19.2114 19.8507 19.1286 19.906 19.0376 19.9437C18.9465 19.9814 18.849 20.0008 18.7504 20.0008C18.6519 20.0008 18.5543 19.9814 18.4632 19.9437C18.3722 19.906 18.2895 19.8507 18.2198 19.781L12.0004 13.5607L5.78104 19.781C5.64031 19.9218 5.44944 20.0008 5.25042 20.0008C5.05139 20.0008 4.86052 19.9218 4.71979 19.781C4.57906 19.6403 4.5 19.4494 4.5 19.2504C4.5 19.0514 4.57906 18.8605 4.71979 18.7198L10.9401 12.5004L4.71979 6.28104C4.57906 6.14031 4.5 5.94944 4.5 5.75042C4.5 5.55139 4.57906 5.36052 4.71979 5.21979C4.86052 5.07906 5.05139 5 5.25042 5C5.44944 5 5.64031 5.07906 5.78104 5.21979L12.0004 11.4401L18.2198 5.21979C18.3605 5.07906 18.5514 5 18.7504 5C18.9494 5 19.1403 5.07906 19.281 5.21979C19.4218 5.36052 19.5008 5.55139 19.5008 5.75042C19.5008 5.94944 19.4218 6.14031 19.281 6.28104L13.0607 12.5004L19.281 18.7198Z"
                        fill="#0E1120"
                      />
                    </svg>
                  </button>
                </div>
                <div className="upper-img text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="101"
                    viewBox="0 0 100 101"
                    fill="none"
                  >
                    <circle cx="50" cy="50.5" r="50" fill="#EFE2F6" />
                    <path
                      d="M49.8086 24.8594V64.578"
                      stroke="#FF0083"
                      stroke-width="7"
                      stroke-linecap="round"
                    />
                    <circle cx="49.8096" cy="73.126" r="3.0166" fill="#FF0083" />
                  </svg>
                </div>
                <div className="submitfee-text">
                  <h6>Are you sure?</h6>
                  <p>Submit Proposal fee Paid to DAO</p>
                  <h5>
                    {" "}
                    worth of TomiToken Required <span>${proposalBaseFee}</span>
                  </h5>
                  <button onClick={submitProposal}>Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Transaction Success Modal===============> */}
      <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal1"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Confirm Transaction</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="\landing\entypo_cross.png" className="img-fluid crossimg" />
                  </button>
                </div>
                <div className="success">
                  <img src="\landing\confirmtransaction.svg" className="img-fluid mb-4" />
                  <p>Transaction Successful!</p>
                </div>
                <div className="confirmbtn">
                  <button onClick={successs}>Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //sumitporposal modal/// */}
      {/* <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal11"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Confirm Transaction</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="\landing\entypo_cross.png" className="img-fluid crossimg" />
                  </button>
                </div>
                <div className='Submitproposal'>
                  <h6>Submit proposal fee $100!</h6>
                  <h6>Worth of Tomi Token</h6>
                </div>
                <div className='confirmbtn'>
                  {allowance < 100 ? <button onClick={Approve}>Approve</button> : <button onClick={submitProposal}>Confirm</button>}
                </div>
                <div className="error-transaction">
                  <div className="upper-img text-center">
                    <img src="\modalpink.png" alt="img" className="img-fluid circleiiner" />
                  </div>
                  <div className="submitfee-text">
                    <h6>Submit Proposal Fee Paid To DAO </h6>
                    <h4 className="mb-4">
                      ${proposalBaseFee} <span> worth of TomiToken</span>
                    </h4>
                    <button onClick={submitProposal}>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="confirmmodal">
        <div
          class="modal fade"
          id="exampleModal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Insufficient Funds</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="\landing\entypo_cross.png" className="img-fluid crossimg" />
                  </button>
                </div>
                <div className="success">
                  <div className="upper-img text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="101"
                      viewBox="0 0 100 101"
                      fill="none"
                    >
                      <circle cx="50" cy="50.5" r="50" fill="#EFE2F6" />
                      <path
                        d="M49.8086 24.8594V64.578"
                        stroke="#FF0083"
                        stroke-width="7"
                        stroke-linecap="round"
                      />
                      <circle cx="49.8096" cy="73.126" r="3.0166" fill="#FF0083" />
                    </svg>
                  </div>
                  <p>Transaction Failed!</p>
                  <p className="">
                    ${proposalBaseFee} <span> worth of TomiToken Required</span>
                  </p>
                </div>
                <div className="confirmbtn">
                  <button onClick={successs1}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* success toast here...................... */}

      {/* <Row>
        <ToastContainer position='top-end' className='p-3 toast-success'>
          <Toast onClose={() => setShow(false)} show={show} delay={3000}>
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\landing\check.svg" alt="img" className='img-fluid' />
                </div>
                <div className="right">
                  <h6>Successs</h6>
                  <p>Vote Submited Successfully</p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <Col xs={6}>
          <Button onClick={() => setShow(true)}>Show Toast</Button>
        </Col>
      </Row> */}

      {/* error toast here...................... */}

      {/* <Row>
      <ToastContainer position='top-end' className='p-3 toast-error'>
      <Toast onClose={() => setShow(false)} delay={3000}>
          <Toast.Header>
           <div className="parent">
            <div className="left">
                <img src="\landing\cancel.svg" alt="img" className='img-fluid' />
            </div>
            <div className="right">
                <h6>Error</h6>
                <p>Vote Submited Failed</p>
            </div>
           </div>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </Row> */}

      {/* warning toast here...................... */}

      {/*   
     <Row>
      <ToastContainer position='top-end' className='p-3 toast-warning'>
      <Toast onClose={() => setShow(false)} delay={3000}>
          <Toast.Header>
           <div className="parent">
            <div className="left">
                <img src="\landing\warning.svg" alt="img" className='img-fluid' />
            </div>
            <div className="right">
                <h6>Error</h6>
                <p>Vote Submited Failed</p>
            </div>
           </div>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </Row>
     */}
    </>
  );
}

export default AddContraction;
