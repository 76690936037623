import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetUsers } from "../../redux/action";
import { API_URL } from "../../utils/ApiUrl";
import Signature from "../../hooks/dataSenders/userSign";

// });
const Navbar = ({ setIndexxxx }) => {
  const { account } = useWeb3React();
  const token = JSON.parse(localStorage.getItem("mytoken"));
  const dispatch = useDispatch();
  const users = useSelector((state) => state.UserReducer.GetUserAll);
  // console.log("allowed user", users);
  const [stickyClass, setStickyClass] = useState(false);
  const { userSign } = Signature();
  // const Token = localStorage.getItem("AccessToken");
  // const history = useHistory();
  const [toggle, setToggle] = useState();
  const { login, logout } = useAuth();
  // dispatch(GetUsers(account));
  // console.log("wallet connect::::::::s", account);
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);
  const openbuttonss = () => {
    setToggle(true);
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // console.log("windowscroll", windowHeight);
      windowHeight > 20 ? setStickyClass(true) : setStickyClass(false);
    }
  };

  const trustWallet = async () => {
    // console.log("here")
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
      localStorage.clear();
    } else {
      login("walletconnect");
      // console.log(account, "accounttrust");
      userRegister();
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      window.$("#exampleModalwallet").modal("hide");
    }
  };

  const connectMetaMask1 = async () => {
    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      userRegister();
      window.$("#exampleModalwallet").modal("hide");
    }
  };

  const userLogin = async () => {
    // console.log("here sign");

    const res1 = await userSign();
    if (res1 && account) {
      axios
        .post(API_URL + "users/login", {
          object: { address: account, name: "Tomi Dao" },
          sign: res1,
        })
        .then((response) => {
          // console.log("resss", response.data.token)
          const token = response.data.token;
          localStorage.setItem("mytoken", JSON.stringify({ token, account }));
          dispatch(GetUsers(account, token));
        })
        .catch((err) => {
          //   setOpen(false)
          //   toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          //   });
        });
      // }
    }
  };

  const Signout = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    await logout(connectorId);
    localStorage.clear();
    // if (Token) {
    //   localStorage.removeItem("AccessToken");
    //   history.push("/home");
    //   window.location.reload();
    //   toast.error("You have Logout", {
    //     position: "top-center",
    //     autoClose: 3000,
    //   });
    // }
  };

  const userRegister = async () => {
    // console.log("hererererssssssssssss",account)x
    // const res1 = await userSign();
    if (account) {
      axios
        .post(API_URL + "users/register", { walletAddress: account })
        .then((response) => {
          // console.log("resss", response)
          userLogin();
          // setLoginnRes(response.data)
          // const token = response.data.token
          // localStorage.setItem('mytoken', token)
          // setOpen(false)
          // history.push('admin/dashboard');
          // window.$('#qrcode').modal('show')
        })
        .catch((err) => {
          // userLogin()
          // console.log("errrrrrrrererererer:::",err)
          // console.log("errrrrrrrererererer",typeof err.toString().slice(39, 43))
          if (err.toString().slice(39, 43) === "401") {
            if (token?.account !== account || token === "null") {
              userLogin();
            }
          }
          // console.log("response in login", err.response?.data.doesSecretKeyFound)
          // window.$('#onlyinput').modal('show')
          // setOpen(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // });
        });
    }
  };
  useEffect(() => {
    if (account) {
      userRegister();
      // dispatch(GetTreasuryNft(account));
    }
  }, [account]);

  return (
    <>
      <section className="main-navbar-mobile">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              <nav className="navbar  navbar-expand-xl " style={{ justifyContent: "flex-end" }}>
                {/* <a href="#" className="navbar-brand sdvgdvsdhvd">
                  <Link to="/">
                    <img
                      src="/logo.svg"
                      alt="img"
                      className="img-fluid gcsgcdgsdvcgc"
                    />
                  </Link>
                </a>
                <div className="ahsbahsba">
                  <Link to="/">
                    <img src="/logo-new.svg" alt="img" className="img-fluid" />
                  </Link>
                </div> */}
                {/* <button
                  onClick={openbuttonss}
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="togg">
                    <i class="fas fa-bars"></i>
                  </span>
                </button> */}
                {/* <div
                  className="collapse navbar-collapse marg"
                  id="navbarSupportedContent"
                > */}
                {/* <ul className="navbar-nav mr-auto">
                    <li class="nav-item">
                      <NavLink to="/home" className="nav-link">
                        DAO
                      </NavLink>
                    </li>
                    <li className="nav-item ">
                      <NavLink to="/realstate" className="nav-link">
                        aNFT
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/www" className="nav-link">
                        Docs{" "}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/mpone" className="nav-link">
                        Discourse
                      </NavLink>
                    </li>
                    <li class="nav-item dropdown"></li>
                  </ul> */}

                <div className="shdvs">
                  {/* {
                    account ? <Link to="/Editprofile">
                    <button className="pinknavbtn" style={{ width: "154px" }}>
                      <img src={users.picture ? users.picture : '/avatar-03.svg'} alt="img" className="btnimage" />
                      {(users.name == 'undefined' || users)? 'Edit Profile' : users.name}</button>
                  </Link> : ""
                  } */}

                  {/* <div className="ajnbdsajdbnsj">
                    {account ?
                      <button
                        className=" btn-common"
                        onClick={Signout}
                        style={{ textAlign: "center", width: '196px' }}
                      >
                        Disconnect
                      </button> :
                      <a
                        href="/SlowMist Audit Report - Tomi.pdf"
                        target="_blank"
                        className=" btn-common" data-toggle="modal" data-target="#exampleModalwallet"
                      >
                        Connect Wallet
                      </a>} */}
                  {/* <a
                        href="https://discord.gg/tomipioneers"
                        target="_blank"
                        className="kk"
                      >
                        <div className="hh">
                          <i class="fab fa-discord"></i>
                        </div>
                      </a>
                      <a
                        href="https://twitter.com/tomipioneers"
                        target="_blank"
                        className="kk"
                      >
                        <div className="gg">
                          <i class="fab fa-twitter"></i>
                        </div>
                      </a>
                      <a
                        href="https://t.me/tomipioneers"
                        target="_blank"
                        className="LL"
                      >
                        <div className="gg">
                          <i class='fab fa-telegram-plane'></i>
                        </div>
                      </a> */}
                  {/* </div> */}
                  {/* {Token ?
                      <button className=" signxs" onClick={Signout}> Logout
                      </button>
                      :
                      <Link to="/login"> <button className=" signxs"> Sign In
                      </button>
                      </Link>
                    } */}
                  {/* {Token ?
                      ""
                      :
                      <Link to="/register">
                        <button className="button-hedaer"> Register
                        </button>
                      </Link>
                    } */}
                  {/* {Token ?
                      <a href="https://user-dashboard.tomi.com/" target="_blank" className="ashvshsv">
                        <button className="button-hedaer"> Dashboard
                        </button>
                      </a>
                      :
                      ""
                    } */}
                  {/* <Link to="/useradmin" className="ashvshsv">

                      <button className="button-hedaer"> Dashboard</button>
                    </Link> */}
                  {/* <a href="/useradmin" className="ashvshsv">
                    </a> */}
                </div>
                {/* </div> */}
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="connectwalletmodal">
        <div
          class="modal fade"
          id="exampleModalwallet"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="main-heading">
                  <h3>Connect Wallet</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                      <path d="M19.281 18.7198C19.3507 18.7895 19.406 18.8722 19.4437 18.9632C19.4814 19.0543 19.5008 19.1519 19.5008 19.2504C19.5008 19.349 19.4814 19.4465 19.4437 19.5376C19.406 19.6286 19.3507 19.7114 19.281 19.781C19.2114 19.8507 19.1286 19.906 19.0376 19.9437C18.9465 19.9814 18.849 20.0008 18.7504 20.0008C18.6519 20.0008 18.5543 19.9814 18.4632 19.9437C18.3722 19.906 18.2895 19.8507 18.2198 19.781L12.0004 13.5607L5.78104 19.781C5.64031 19.9218 5.44944 20.0008 5.25042 20.0008C5.05139 20.0008 4.86052 19.9218 4.71979 19.781C4.57906 19.6403 4.5 19.4494 4.5 19.2504C4.5 19.0514 4.57906 18.8605 4.71979 18.7198L10.9401 12.5004L4.71979 6.28104C4.57906 6.14031 4.5 5.94944 4.5 5.75042C4.5 5.55139 4.57906 5.36052 4.71979 5.21979C4.86052 5.07906 5.05139 5 5.25042 5C5.44944 5 5.64031 5.07906 5.78104 5.21979L12.0004 11.4401L18.2198 5.21979C18.3605 5.07906 18.5514 5 18.7504 5C18.9494 5 19.1403 5.07906 19.281 5.21979C19.4218 5.36052 19.5008 5.55139 19.5008 5.75042C19.5008 5.94944 19.4218 6.14031 19.281 6.28104L13.0607 12.5004L19.281 18.7198Z" fill="#0E1120" />
                    </svg>
                  </button>
                </div>
                <div className="Submitproposal">
                  <div className="iconimages">
                    <div className="leftimage" onClick={connectMetaMask1}>
                      <img src="\assets\metamassk.svg" className="img-fluid" />
                      <p>Metamask</p>
                    </div>
                    <div className="leftimage" onClick={trustWallet}>
                      <img src="\assets\wallet.svg" className="img-fluid" />
                      <p>WalletConnect</p>
                    </div>
                  </div>
                </div>
                <div className="clear">
                  {/* <button>Clear WalletConnect Data</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
