import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import "./editprofile.scss";
import { useWeb3React } from "@web3-react/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import Loader from "../../hooks/loader";
import { GetUsers } from "../../redux/action";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import Signature from "../../hooks/dataSenders/userSign";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
  position: absolute;
  right: 14px;
  top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ff0083;
  text-decoration: none;
  border: none;
  background-color: transparent;
`;
const HOVER_TIMEOUT = 1000;

const Editprofile = () => {
  const dispatch = useDispatch();
  const { userSign } = Signature();
  const token = JSON.parse(localStorage?.getItem("mytoken"));
  const history = useHistory();
  const users = useSelector((state) => state.UserReducer.GetUserAll);
  console.log("eserr", users);
  const [selectedImg1, setSelectedImg1] = useState();
  const [mainLoader, setMainLoader] = useState(false);
  const [myFiles, setMyFiles] = useState();
  const { account } = useWeb3React();
  const [copy, setCopy] = useState(false);
  const [inputs, setInputs] = useState({
    userEmail: "",
    name: "",
    discordLink: "",
    walletAddress: "",
  });

  console.log("inputsssss", inputs);

  const userLogin = async () => {
    const res1 = await userSign();
    if (res1 && account) {
      console.log("herererer");
      axios
        .post(API_URL + "users/login", {
          object: { address: account, name: "Tomi Dao" },
          sign: res1,
        })
        .then((response) => {
          console.log("resss", response.data.token);
          const token = response.data.token;
          localStorage.setItem("mytoken", JSON.stringify({ token, account }));
          dispatch(GetUsers(account, token));
        })
        .catch((err) => {
          //   setOpen(false)
          //   toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          //   });
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const editUser = async (event) => {
    setMainLoader(true);
    event.preventDefault();
    const data = new FormData();
    if (inputs.name) {
      data.append("name", inputs?.name);
    } else {
      data.append("name", users?.name);
    }
    if (inputs.userEmail) {
      data.append("userEmail", inputs.userEmail);
    } else {
      data.append("userEmail", users?.userEmail);
    }
    if (inputs.discordLink) {
      data.append("discordLink", inputs.discordLink);
    } else {
      data.append("discordLink", users?.discordLink);
    }

    // data.append("userEmail", inputs.userEmail ? inputs.userEmail : users?.userEmail );
    // data.append("discordLink", inputs.discordLink ? inputs.discordLink : users?.discordLink);
    if (myFiles) {
      data.append("picture", myFiles);
    }
    data.append("walletAddress", account);
    //   data.append("name", "any");
    data.append("role", "user");

    console.log("yeeeeeee");
    axios
      .post(API_URL + "users/editUser", data, {
        headers: { Authorization: `Bearer ${token?.token}` },
      })
      .then((response) => {
        setMainLoader(false);
        toast.success("Profile Edited", {
          position: "top-center",
          autoClose: 2000,
        });

        history.push("/home");

        dispatch(GetUsers(account, token?.token));
        //   history.push("/profile");
        // setInputs({
        //     name: '',
        //     description: '',
        //     routeLink: '',
        //     routePage: '',
        //     category: '',
        //     type: '',
        //     image: ''
        // })
        // setSelectedImg('');
      })
      .catch((err) => {
        if (err.toString().slice(39, 43) === "401") {
          userLogin();
        }

        setMainLoader(false);
        toast.error(err.response.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
    // } else {
    //   setMainLoader(false);
    //   toast.error("name cannot be empty", {
    //     position: "top-center",
    //     autoClose: 2000,
    //   });
    // }
  };

  const deleteProfile = () => {
    setMainLoader(true);
    axios
      .post(
        API_URL + `users/deleteCoverPhoto`,
        {},
        { headers: { Authorization: `Bearer ${token?.token}` } }
      )
      .then((response) => {
        setMainLoader(false);
        toast.success("Profile Image Deleted", {
          position: "top-center",
          autoClose: 2000,
        });
        dispatch(GetUsers(account, token?.token));
        //   history.push("/profile");
      })
      .catch((error) => {
        setMainLoader(false);
      });
    setMyFiles();
    setSelectedImg1(null);
  };

  const handleFileSelect = (evt) => {
    if (evt.target.files) {
      const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));
      setSelectedImg1(filesarray[0]);
    }
    var files = evt.target.files;
    var file = files[0];
    setMyFiles(file);
  };
  return (
    <>
      {mainLoader && <Loader />}
      <section className="Editprofile">
        <img src="\assets\shaade.png" className="linesshades img-fluid"></img>
        <div className="container-fluid p-0">
          <div className="uppper_part">
            <div className="mainheadings">
              <h4 className="head">Edit profile</h4>
              <p className="para">
                You can set preferred display name and manage other personal settings All Fields Are
                Optional.
              </p>
            </div>
            <div className=" myyydirection">
              <div className="rightmain">
                <div className="maininner">
                  <img
                    src={
                      selectedImg1 ? selectedImg1 : users.picture ? users.picture : "avatar-03.svg"
                    }
                    className="avatarimg"
                    alt="img"
                  />
                  <div className="innered">
                    <h6>Upload profile image</h6>
                    <p>
                      We recommend to upload images in 400x400 resolution. Max 5 MB in JPEG or PNG
                      format
                    </p>
                    <div className="inning">
                      <label className="upload-btn" htmlFor="upload">
                        Upload Image
                      </label>
                      <input
                        type="file"
                        className="d-none"
                        onChange={handleFileSelect}
                        accept="image/*"
                        id="upload"
                      />
                      <div className="" onClick={deleteProfile}>
                        <img src="\assets\trassh.svg" alt="img" className="trash_icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uppper_inputs">
                  <div className="firstttt">
                  <div className="maininput">
                      <input
                        type="text"
                        name="name"
                        value={inputs?.name ? inputs?.name : users.name}
                        onChange={handleChange1}
                        placeholder="Name (Optional)"
                        className="innerinput"
                      />
                    </div>
                    <div className="maininput">
                      <input
                        type="text"
                        name="discordLink"
                        value={inputs?.discordLink ? inputs?.discordLink : users.discordLink}
                        onChange={handleChange1}
                        placeholder="Discord (Optional)"
                        className="innerinput"
                      />
                    </div>
                   
                  </div>
                  <div className="firstttt">
                    <div className="maininput">
                      <input
                        type="text"
                        name="userEmail"
                        value={inputs?.userEmail ? inputs?.userEmail : users.userEmail}
                        onChange={handleChange1}
                        placeholder="Email (Optional)"
                        className="innerinput"
                      />
                    </div>

                    <div className="maininput">
                      <div>
                        {/* <input
                          type="text"
                          value={account ? account : "0x23BcE9222ef542670e4...D8d"}
                          placeholder="0x23BcE9222ef542670e4...D8d"
                          name="walletAddress"
                          className="innerinput disble"
                          readOnly
                        /> */}
                        <div className="copyytextual_div">
                        0x23BcE9222ef542670e4...D8d
                        </div>
                        {/* <button className='copy'>Copy</button> */}
                        {copy ? (
                          <Copied className="copiedtext">Copied</Copied>
                        ) : (
                          // {account}
                          <CopyToClipboard
                            text={account}
                            onCopy={() => {
                              setCopy(true);
                              setTimeout(() => setCopy(false), HOVER_TIMEOUT);
                            }}
                          >
                            <button className="copy">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M13.3327 10.7503V14.2503C13.3327 17.167 12.166 18.3337 9.24935 18.3337H5.74935C2.83268 18.3337 1.66602 17.167 1.66602 14.2503V10.7503C1.66602 7.83366 2.83268 6.66699 5.74935 6.66699H9.24935C12.166 6.66699 13.3327 7.83366 13.3327 10.7503Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.3327 5.75033V9.25033C18.3327 12.167 17.166 13.3337 14.2494 13.3337H13.3327V10.7503C13.3327 7.83366 12.166 6.66699 9.24935 6.66699H6.66602V5.75033C6.66602 2.83366 7.83268 1.66699 10.7493 1.66699H14.2494C17.166 1.66699 18.3327 2.83366 18.3327 5.75033Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </button>
                          </CopyToClipboard>
                        )}
                        {/* <buttton href='' className='copy'/>Copy */}
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <button className="endbtn" onClick={editUser}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Editprofile;
