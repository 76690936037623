import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section className="main-footer">
        <div className="footer_data">
          <img src="\assets\footorshade.png" alt="img" className="img-fluid footorshadow"/>
          {/* <img src="\assets\shadowfooter.svg" alt="img" className="fottershade" /> */}
          <div className="footertop">
            <div className="topleft">
              <a href="/">
              <img src="/assets/Logo.svg" alt="topleftimg" className="topleftimg" />

              </a>
              {/* <p className="topleftpara">A New Era of Online Freedom</p> */}
              <div className="topright">
                <a
                  href="https://twitter.com/tomipioneers"
                  target="_blank"
                  className="sociallink"
                  rel="me"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    {/* <mask
                      id="mask0_1246_2068"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="6"
                      y="6"
                      width="12"
                      height="13"
                    >
                      <path d="M6 6.75H18V18.75H6V6.75Z" fill="white" />
                    </mask> */}
                    <g mask="url(#mask0_1246_2068)">
                      <path
                        d="M15.45 7.30859H17.2903L13.2703 11.9149L18 18.184H14.2971L11.3949 14.3826L8.07771 18.184H6.23571L10.5351 13.2555L6 7.30945H9.79714L12.4166 10.7835L15.45 7.30859ZM14.8029 17.08H15.8229L9.24 8.35517H8.14629L14.8029 17.08Z"
                        fill="#0E1120"
                      />
                    </g>
                    <circle cx="12" cy="12.75" r="11.5" stroke="#0E1120" />
                  </svg>
                </a>
                <a
                  href="https://t.me/tomi_official_chat"
                  className="sociallink"
                  target="_blank"
                  rel="me"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1246_2075)">
                      <path
                        d="M10.1011 14.2497L9.88605 17.3209C10.1937 17.3209 10.327 17.1867 10.4868 17.0256L11.9293 15.6258L14.9183 17.8484C15.4664 18.1586 15.8526 17.9952 16.0005 17.3363L17.9625 8.00177L17.963 8.00122C18.1369 7.17842 17.67 6.85667 17.1359 7.05852L5.60353 11.5415C4.81647 11.8517 4.82838 12.2972 5.46973 12.4991L8.4181 13.4302L15.2666 9.07921C15.5889 8.86251 15.8819 8.98241 15.6409 9.19911L10.1011 14.2497Z"
                        fill="#0E1120"
                      />
                      <circle cx="12" cy="12.75" r="11.5" stroke="#0E1120" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1246_2075">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.75)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  href="https://discord.com/invite/tomipioneers"
                  className="sociallink"
                  target="_blank"
                  rel="me"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <g clip-path="url(#clip0_1246_2078)">
                      <circle cx="12" cy="12.75" r="11.5" stroke="#0E1120" />
                      <path d="M9.88817 8C9.48171 8 8.25628 8.50372 8.03643 8.60439C7.81658 8.70506 7.57255 9.01931 7.29566 9.53796C7.01838 10.0566 6.79589 10.6629 6.43438 11.5548C6.07325 12.4466 5.98901 14.166 6.0011 14.6908C6.01318 15.2156 6.07249 15.6255 6.60323 15.9359C7.1336 16.246 7.60277 16.5706 8.09649 16.7658C8.59059 16.961 8.92755 17.1015 9.15647 16.9063C9.38538 16.711 9.53611 16.3692 9.53611 16.3692C9.53611 16.3692 9.75294 16.0642 9.34345 15.8812C8.93397 15.6979 8.72923 15.5696 8.74698 15.3805C8.76511 15.1915 8.79496 15.0877 8.89733 15.1122C8.9997 15.1367 9.24108 15.5758 10.1685 15.7771C11.0958 15.9784 11.9998 15.9482 11.9998 15.9482C11.9998 15.9482 12.9042 15.9788 13.8315 15.7771C14.7589 15.5758 14.9999 15.1367 15.1023 15.1122C15.2047 15.0877 15.2345 15.1915 15.2526 15.3802C15.2708 15.5696 15.066 15.6979 14.6565 15.8812C14.2471 16.0642 14.4639 16.3692 14.4639 16.3692C14.4639 16.3692 14.6146 16.7107 14.8435 16.9063C15.0721 17.1015 15.4094 16.961 15.9031 16.7658C16.3969 16.5706 16.8664 16.2464 17.3968 15.9359C17.9275 15.6255 17.9868 15.2156 17.9989 14.6908C18.011 14.1664 17.9268 12.4466 17.5652 11.5548C17.2041 10.6629 16.9812 10.0566 16.704 9.53796C16.4275 9.01931 16.183 8.70506 15.9636 8.60401C15.7437 8.50372 14.5179 8 14.1115 8C13.705 8 13.5849 8.28708 13.5849 8.28708L13.4432 8.60439C13.4432 8.60439 12.4913 8.42105 12.0062 8.42066C11.5212 8.42066 10.5568 8.60439 10.5568 8.60439L10.4151 8.28669C10.4151 8.28669 10.295 8 9.88817 8ZM9.93237 11.8078H9.94748C10.5402 11.8078 11.0203 12.3333 11.0203 12.9813C11.0203 13.6298 10.5402 14.1549 9.94748 14.1549C9.35479 14.1549 8.87466 13.6298 8.87466 12.9813C8.87428 12.3394 9.34572 11.8166 9.93237 11.8078ZM14.0525 11.8078H14.0676C14.6543 11.8166 15.1253 12.3398 15.1253 12.9813C15.1253 13.6298 14.6452 14.1549 14.0525 14.1549C13.4594 14.1549 12.9797 13.6298 12.9797 12.9813C12.9797 12.3333 13.4594 11.8078 14.0525 11.8078Z" fill="#0E1120" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1246_2078">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.75)" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  href="https://coinmarketcap.com/currencies/tominet/"
                  className="sociallink"
                  target="_blank"
                  rel="me"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <g clip-path="url(#clip0_1246_2081)">
                      <path d="M17.1738 14.1156C16.9258 14.2702 16.6339 14.2894 16.4124 14.1663C16.1306 14.0088 15.9755 13.6425 15.9755 13.1315V11.6043C15.9755 10.867 15.6794 10.342 15.1844 10.2003C14.3461 9.95933 13.7149 10.9702 13.4781 11.3506L11.999 13.7131V10.8256C11.9825 10.1606 11.7634 9.76333 11.3477 9.64317C11.0718 9.56442 10.6603 9.59592 10.2601 10.1991L6.94618 15.4415C6.50255 14.6117 6.27126 13.6878 6.27239 12.75C6.27239 9.59183 8.84144 7.02283 11.999 7.02283C15.156 7.02283 17.7245 9.59183 17.7245 12.75C17.7269 12.7605 17.7257 12.7698 17.7263 12.7809C17.7559 13.3928 17.5546 13.8793 17.1738 14.1167V14.1156ZM18.9975 12.7506L18.9969 12.7185C18.9791 8.87258 15.847 5.75 11.9984 5.75C8.13982 5.75 5 8.89008 5 12.75C5 16.6093 8.13982 19.75 11.9984 19.75C13.7694 19.75 15.4598 19.0867 16.757 17.8833C17.0146 17.6442 17.0294 17.2417 16.7908 16.9844C16.7345 16.9232 16.6664 16.8737 16.5904 16.8388C16.5144 16.8039 16.4322 16.7842 16.3484 16.781C16.2647 16.7778 16.1811 16.7911 16.1026 16.8201C16.0241 16.8491 15.9523 16.8933 15.8914 16.95C14.84 17.9308 13.447 18.4773 11.999 18.4772C10.308 18.4772 8.78697 17.7404 7.7378 16.5708L10.7266 11.8429V14.0228C10.7266 15.0693 11.1387 15.4077 11.4845 15.5068C11.8303 15.606 12.3584 15.5383 12.9132 14.6505C13.5041 13.709 14.1122 12.7273 14.7031 11.8038V13.1315C14.7031 14.1103 15.101 14.8932 15.7943 15.2782C16.4195 15.6264 17.2058 15.5949 17.8459 15.1965C18.6227 14.7135 19.0407 13.8233 18.9969 12.7506H18.9975Z" fill="#0E1120" />
                      <circle cx="12" cy="12.75" r="11.5" stroke="#0E1120" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1246_2081">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.75)" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>

            </div>
            <div className="footermidleft">
            <div className="midinner">
                <h6 className="midhead">Infrastructure</h6>

                <a href="https://domains.tomi.com/" target="_blank" className="midpara">
                  Domains
                </a>
                <a href="https://tomi.com/storage" target="_blank" className="midpara">
                  Storage
                </a>
                <a href="https://staking.tomi.com/" target="_blank" className="midpara">Staking</a>
                <a href="https://tomi.com/wallet" target="_blank" className="midpara">
                  Wallet
                </a>
              </div>
              <div className="midinner">
                <h6 className="midhead">What we are</h6>
                <a href="https://tomi.com/depin/storage/" target="_blank" className="midpara">
                  About Us
                </a>
                <a href="https://tomi.com/cdn/" target="_blank" className="midpara">
                  Roadmap
                </a>
                <a href="https://tomi.com/depin/tomi-node/" target="_blank" className="midpara">
                  White-paper
                </a>
                <a href="https://tomi.com/depin/hosting/" target="_blank" className="midpara">
                  Brand Guidelines
                </a>
              </div>
              <div className="midinner">
                <h6 className="midhead">In-app features</h6>
                <a href="https://tomi.com/web3/browser/" target="_blank" className="midpara">
                  Chat & Pay
                </a>
                <a href="https://domains.tomi.com/" target="_blank" className="midpara">
                  Build Groups
                </a>
                <a href="https://tomi.com/web3/wallet/" target="_blank" className="midpara">
                  Create Profile
                </a>
                <a href="https://tomi.com/web3/vpn/" target="_blank" className="midpara">
                  Browse Securely
                </a>
                {/* <a
                  href="https://tomi.com/web3/private-messaging/"
                  target="_blank"
                  className="midpara"
                >
                  Private Messaging
                </a>
                <a href="https://dao.tomi.com/home" target="_blank" className="midpara">
                  DAO
                </a>
                <a href="https://staking.tomi.com/" target="_blank" className="midpara">
                  Staking
                </a> */}
              </div>



              <div className="midinner">
                <h6 className="midhead">Need help</h6>
                <a href="https://tomi.com/contactus" target="_blank" className="midpara">
                  Contact Us
                </a>
                <a href="https://tomi.com/support/welcome" target="_blank" className="midpara">
                  Support
                </a>
                {/* <a href="https://tomi.com/about/roadmap/" target="_blank" className="midpara">
                  FAQ
                </a>
                <a href="https://tomi.com/about/blog/" target="_blank" className="midpara">
                  Terms
                </a> */}
              </div>
              {/* <div className="midinner">
                <h6 className="midhead">Web3</h6>
                <a href="https://tomi.com/web3/browser/" target="_blank" className="midpara">
                  Browser
                </a>
                <a href="https://domains.tomi.com/" target="_blank" className="midpara">
                  Domains
                </a>
                <a href="https://tomi.com/web3/wallet/" target="_blank" className="midpara">
                  Wallet
                </a>
                <a href="https://tomi.com/web3/vpn/" target="_blank" className="midpara">
                  VPN
                </a>
                <a
                  href="https://tomi.com/web3/private-messaging/"
                  target="_blank"
                  className="midpara"
                >
                  Private Messaging
                </a>
                <a href="https://dao.tomi.com/home" target="_blank" className="midpara">
                  DAO
                </a>
                <a href="https://staking.tomi.com/" target="_blank" className="midpara">
                  Staking
                </a>
              </div> */}
            </div>
          </div>
          {/* <div className="footermid">
            <div className="footermidleft">
              <div className="midinner">
                <h6 className="midhead">Web3</h6>
                <a href="https://tomi.com/web3/browser/" target="_blank" className="midpara">
                  Browser
                </a>
                <a href="https://domains.tomi.com/" target="_blank" className="midpara">
                  Domains
                </a>
                <a href="https://tomi.com/web3/wallet/" target="_blank" className="midpara">
                  Wallet
                </a>
                <a href="https://tomi.com/web3/vpn/" target="_blank" className="midpara">
                  VPN
                </a>
                <a
                  href="https://tomi.com/web3/private-messaging/"
                  target="_blank"
                  className="midpara"
                >
                  Private Messaging
                </a>
                <a href="https://dao.tomi.com/home" target="_blank" className="midpara">
                  DAO
                </a>
                <a href="https://staking.tomi.com/" target="_blank" className="midpara">
                  Staking
                </a>
              </div>
              <div className="midinner">
                <h6 className="midhead">DePIN</h6>
                <a href="https://tomi.com/depin/storage/" target="_blank" className="midpara">
                  Storage
                </a>
                <a href="https://tomi.com/cdn/" target="_blank" className="midpara">
                  CDN
                </a>
                <a href="https://tomi.com/depin/tomi-node/" target="_blank" className="midpara">
                  tomi Node
                </a>
                <a href="https://tomi.com/depin/hosting/" target="_blank" className="midpara">
                  Hosting
                </a>
              </div>
              <div className="midinner">
                <h6 className="midhead">Communities</h6>
                <a href="https://tomi.com/community/" target="_blank" className="midpara">
                  Community
                </a>
                <a href="https://tomiarmy.com/" target="_blank" className="midpara">
                  tomi Army
                </a>
                <a href="https://tomi.com/communities/careers/" target="_blank" className="midpara">
                  Careers
                </a>
              </div>
              <div className="midinner">
                <h6 className="midhead">About</h6>
                <a href="https://tomi.com/about/intro-to-tomi/" target="_blank" className="midpara">
                  Intro to tomi
                </a>
                <a href="https://tomi.com/about/vision/" target="_blank" className="midpara">
                  Vision
                </a>
                <a href="https://tomi.com/about/roadmap/" target="_blank" className="midpara">
                  Roadmap
                </a>
                <a href="https://tomi.com/about/blog/" target="_blank" className="midpara">
                  Blog
                </a>
              </div>
              <div className="midinner">
                <h6 className="midhead">Web3</h6>
                <a href="https://tomi.com/web3/browser/" target="_blank" className="midpara">
                  Browser
                </a>
                <a href="https://domains.tomi.com/" target="_blank" className="midpara">
                  Domains
                </a>
                <a href="https://tomi.com/web3/wallet/" target="_blank" className="midpara">
                  Wallet
                </a>
                <a href="https://tomi.com/web3/vpn/" target="_blank" className="midpara">
                  VPN
                </a>
                <a
                  href="https://tomi.com/web3/private-messaging/"
                  target="_blank"
                  className="midpara"
                >
                  Private Messaging
                </a>
                <a href="https://dao.tomi.com/home" target="_blank" className="midpara">
                  DAO
                </a>
                <a href="https://staking.tomi.com/" target="_blank" className="midpara">
                  Staking
                </a>
              </div>
            </div>
            <div className="footermidright">
              <div className="midinner">
                <a href="https://tomi.com/terms-of-use/" target="_blank" className="midpara">
                  Terms of use
                </a>
                <a href="https://tomi.com/privacy-policy/" target="_blank" className="midpara">
                  Privacy policy
                </a>
                <a
                  href="https://cb38b0e4907742ad9f19a131zcf0vs7f.cc.clym.io/ "
                  target="_blank"
                  className="midpara"
                >
                  Compliance Center
                </a>
                <a
                  href="https://cb38b0e4907742ad9f19a131zcf0vs7f.cc.clym.io/requests/new/do-not-sell-or-share"
                  target="_blank"
                  className="midpara"
                >
                  Request Your Stored Personal Data
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="lastbtn  d-none">
            <button className="entbtnsss">Get the App</button>
          </div> */}
          <div className="footerlast">
            <span className="footerlastline"></span>
            {/* ©{currentYear}, tomi. All rights reserved. */}
            <div className="endpara">
              <Link to="/termsofuse">
              <h1 className="paraend">Terms of use</h1>

              </Link>
              <a  href="/privacypolicy" className="paraend">Privacy policy</a>
              {/* <a className="paraend">Cookie Preferences</a> */}
            </div>
          </div>


        </div>
      </section>
    </>
  );
};

export default Footer;
