import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./buttonComponent.scss";
import useWeb3 from "../../hooks/useWeb3";
import { getTomiContract } from "../../utils/contractHelpers";
import Modal from "react-bootstrap/Modal";

import { useWeb3React } from "@web3-react/core";
import { getWeb3NoAccount } from "../../utils/web3";
import {
  UserData,
  UserVoteStatus,
  useNftUsed,
  ProposalStatus,
  UserNftVoted,
  useTomiBalance,
  GetConsensus,
  useVotes,
  ProposalPostState,
  useCheckDel,
} from "../../hooks/useBalances";
import UserVote from "../../hooks/dataSenders/vote";
import UserDelegate from "../../hooks/dataSenders/delegate";
import UserExecute from "../../hooks/dataSenders/executes";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import { API_URL } from "../../utils/ApiUrl";
import Signature from "../../hooks/dataSenders/userSign";
import { useDispatch } from "react-redux";
import { GetUsers } from "../../redux/action";
import Environment from "../../utils/Environment";
import { ProofStep } from "merkletreejs/dist/MerkleSumTree";
import useAuth from "../../hooks/useAuth";

function Executed() {
  const { login, logout } = useAuth();
  const [showwalletmobile, setShowWalletmobile] = useState(false);
  const [showwalletmobile11, setShowWalletmobile11] = useState(false);
  const handleClosewalletmobile = () => setShowWalletmobile(false);
  const handleClosewalletmobile11 = () => setShowWalletmobile11(false);
  const handleShowwalletmobile = () => {
    window.$("#exampleModalwalletmobile").modal("hide");
    setShowWalletmobile(true);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let { id, address } = useParams();
  id = id >= 21 ? parseInt(id) + 1 : id;
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const [id1, setId1] = useState(null);
  useEffect(() => {
    if (id) {
      setId1(id);
    }
  }, [id]);
  const [proposal, setProposals] = useState();
  const token = JSON.parse(localStorage.getItem("mytoken"));
  const { CheckNftVoted } = UserNftVoted();
  const [userPropseTRx, setuserPropseTRx] = useState();
  const dispatch = useDispatch();
  const { userSign } = Signature();
  const [inputs, setInputs] = useState("");
  const [vote, setVote] = useState();
  const [userAllNft, setUserAllNft] = useState([]);
  const [mainLoader, setMainLoader] = useState(false);
  // const minReqNFtVote = useMinVOTEReq();
  const { CheckUserBalance } = useTomiBalance();
  const [mainDisable, setMainDisable] = useState(false);
  const [allCommentss, setAllCommentss] = useState([]);
  const [userTomiBalance, setUserTomiBalance] = useState(0);
  const [ABiFun, setABiFun] = useState();
  const userInfo = UserData(id);
  const voteStatus = UserVoteStatus(id, account);
  const VoteAvail = useVotes(account);
  const delCheck = useCheckDel(account);
  const a = parseInt(Date.now() / 1000);
  const proposalPostState = ProposalPostState(id, address);
  // console.log("loader", a);
  // console.log("this component", proposal?.legacy);
  const ProposalUserStatus = ProposalStatus(id);
  // console.log("df:PropseTRx", ProposalUserStatus, proposalPostState, userInfo);
  const { voted } = UserVote();
  const { del } = UserDelegate();
  const { execute } = UserExecute();

  const getProposals = async () => {
    // setMainLoader(true);
    var data = JSON.stringify({
      query: ` {
                proposalCreateds(
                    where: {proposalId: "${id}"}
                  ) {
                     proposer
                     id
                     proposalId
                     proposer
                     targets
                     values
                     signatures
                     calldatas
                     startTime
                     endTime
                     title
                     description
                     quorumVotes
                     consensusVotes
                     forVotes
                     againstVotes
                     legacy
                     abstainVotes
                     state
                       transaction {
      id
    }

                  }
            }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/63987/tomi-dao-live/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        setProposals(response.data.data.proposalCreateds[0]);
        // setMainLoader(false);
        //   console.log("ressssss",response.data.data)
      })
      .catch(function (error) {
        console.log(error);
        // setMainLoader(false);
      });
  };

  const userNFt = () => {
    setMainLoader(true);
    const options = {
      method: "GET",
      url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.MintContract}`,
      headers: {
        accept: "application/json",
        "X-API-Key":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MTFmMDdiLWZkNGMtNDBmZS05MjQ0LWU5ZmNlOWVlNjY2MiIsIm9yZ0lkIjoiMjA5OTMxIiwidXNlcklkIjoiMjA5NjAzIiwidHlwZUlkIjoiNjgzMjA5NjgtZTEzNy00MTA1LWFjNDYtM2ZlNGRmNDViMGZlIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTY0OTAzNDEsImV4cCI6NDg3MjI1MDM0MX0.GR-teydWjr1g7RLcBh_x1QC-ECgy7Z6e-nTmGiE2qJ8",
      },
    };

    const a = axios.request(options).then(async function (response) {
      console.log(response.data.total);
      let dummArr = [];
      // for (let i = 0; i < response?.data?.total; i++) {
      //     //   console.log("innnnnnnnnnn loooop", response?.data?.result[i].token_id);
      //     let ab = await CheckNftVoted(response?.data?.result[i]?.token_id, id)
      //     console.log("ABBBBB", ab)
      //     if (ab === false) {
      //         dummArr.push(response?.data?.result[i]?.token_id)
      //     }
      // }
      let promise = await Promise.all(
        response?.data?.result.map((data) => {
          return CheckNftVoted(data?.token_id, id);
          // console.log("ABBBBB", ab)
          // if (ab === false) {
          // return  dummArr.push(data?.token_id)
          // }
        })
      );
      for (let i = 0; i < promise?.length; i++) {
        console.log("ABBBBB111loooop", promise[i]);
        if (promise[i] === false) {
          dummArr.push(response?.data?.result[i]?.token_id);
        }
      }
      console.log("ABBBBB111", dummArr);
      setUserAllNft(dummArr);
      setMainLoader(false);
      return response.data.result;
    });
    return a.catch(function (error) {
      console.error(error);
      setMainLoader(false);
    });
  };

  const ExecuteProposal = async () => {
    // debugger;
    setMainLoader(true);
    setId1(null);
    // console.log('this component:', a)
    // if (userInfo?.functionId === '1') {
    //     a='updateEmissions'
    //   } else if (userInfo?.functionId === '2') {
    //       a='updateMarketingWallet'
    //   } else if (userInfo?.functionId === '3') {
    //     a='updateTomiWallet'
    //   } else if (userInfo?.functionId === '4') {
    //     a='changeBlockState'
    //   }
    try {
      // setMainLoader(true)
      const res = await execute(id.toString(), account);

      if (res) {
        // console.log("proposal res", res)
        setId1(id);
        getProposals();
        window.location.reload();
        setMainLoader(false);
        // setMainLoader(false);
        await toast.success("Proposal Executed Successfully");
      }
      // history.push('/home')
    } catch (error) {
      setMainLoader(false);
      toast.error(error.message);
      console.log("eerrr", error);
      // await setError(error.message)
      // setMainLoader(false);
    }
  };

  const Delgatecall = async () => {
    const web3 = getWeb3NoAccount();
    const tokenAddress = Environment.TomiTokenContract;
    const contract = getTomiContract(tokenAddress, web3);
    const approved = await contract.methods.getVotes(account).call();
    return approved;
  };

  const delegateVote = async () => {
    setMainLoader(true);
    try {
      const res = await del(account);
      console.log("dele111", res);
      if (res) {
        // console.log("proposal res", res)
        setMainLoader(false);
        // setMainLoader(false);
        await toast.success("Vote Delegate Successfully");
        window.location.reload();
        // return res;
        // setMainLoader(false);
        // setMainLoader(false);
        // await toast.success("Vote Submitted Successfully");
      }
    } catch (error) {
      setMainLoader(false);
    }
  };

  const VoteFor = async () => {
    // window.$("#exampleModalvote").modal("hide");
    setMainLoader(true);
    setId1(null);
    // 0x0000000000000000000000000000000000000000;
    console.log("data", typeof delCheck);

    if (VoteAvail > 0) {
      try {
        const res = await voted(id.toString(), vote.toString(), account, VoteAvail);
        getProposals();
        setId1(id);
        window.location.reload();
        // console.log("proposal res", res)
        setMainLoader(false);
        window.$("#exampleModalvote").modal("hide");

        // setMainLoader(false);
        await toast.success("Vote Submitted Successfully");
      } catch (error) {
        setMainLoader(false);
        window.$("#exampleModalvote").modal("hide");
        window.location.reload();
        toast.error(error.message);
        console.log("eerrr", error);
        // await setError(error.message)
        // setMainLoader(false);
      }
    }
  };

  const userLogin = async () => {
    const res1 = await userSign();
    if (res1 && account) {
      console.log("herererer");
      axios
        .post(API_URL + "users/login", {
          object: { address: account, name: "Tomi Dao" },
          sign: res1,
        })
        .then((response) => {
          console.log("resss", response.data.token);
          const token = response.data.token;
          localStorage.setItem("mytoken", JSON.stringify({ token, account }));
          dispatch(GetUsers(account, token));
        })
        .catch((err) => {
          //   setOpen(false)
          //   toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          //   });
        });
    }
  };

  const gata = async () => {
    // let res1111 = await CheckUserNft();
    // let res2 = await UserWalleet(account);
    let balance = await CheckUserBalance();
    setUserTomiBalance(balance);
    if (userInfo) {
      console.log("gata", userInfo?.signatures);
      for (let i = 0; i < userInfo?.signatures.length; i++) {
        if (userInfo?.signatures[i].startsWith("updateEmissions")) {
          let s = userInfo?.signatures[i].split("((")[1];
          s = s?.replace("))", "");
          s = s?.split(",");

          let final = web3.eth.abi.decodeParameters(s, userInfo?.calldatas[i]);
          setuserPropseTRx(final);
          console.log("sssss", final);
          for (let i = 0; i < userInfo?.signatures.length; i++) {
            let s = userInfo?.signatures[i].split("((")[0];
            setABiFun(s);
            // s = s.replace(")" , "")
            // s = s.split(",")
            // let final = web3.eth.abi.decodeParameters(s,userInfo.calldatas[i])
            // setuserPropseTRx(final)
            // console.log('sssss' , s)
          }
        } else if (userInfo?.signatures[i].startsWith("fundDao")) {
          let s = userInfo?.signatures[i].split("(")[0];
          setABiFun(s);
        } else {
          let s = userInfo?.signatures[i].split("(")[1];
          s = s?.replace(")", "");
          s = s?.split(",");
          console.log("sssssssss", s);
          let final = web3?.eth?.abi?.decodeParameters(s, userInfo?.calldatas[i]);
          setuserPropseTRx(final);
          console.log("sssss", s);

          for (let i = 0; i < userInfo?.signatures.length; i++) {
            let s = userInfo?.signatures[i].split("(")[0];
            setABiFun(s);
            // s = s.replace(")" , "")
            // s = s.split(",")
            // let final = web3.eth.abi.decodeParameters(s,userInfo.calldatas[i])
            // setuserPropseTRx(final)
            // console.log('sssss' , s)
          }
        }

        // let res1 = await execute1(id1, address, userInfo?.functionId);
        // setProposedTrx(res1)
        // console.log("res gata", res1[0])
      }
    }
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const addComment = () => {
    // setMainLoader(true)
    //     setOpen(true)
    if (inputs.comment) {
      setMainDisable(true);
      axios
        .post(
          API_URL + "comment/addComment",
          { comment: inputs.comment, proposalId: parseInt(id) },
          { headers: { Authorization: `Bearer ${token?.token}` } }
        )
        .then((response) => {
          console.log("resssssss", response);
          if (response) {
            setInputs({ comment: "" });
            getAllComents();
            setMainDisable(false);
          }
          // setMainLoader(false)
          // const token = response.data.token
          // localStorage.setItem('mytoken', token)
          // window.$('#qrcode').modal('show')
        })
        .catch((err) => {
          setMainDisable(false);
          // console.log("response in login", err.response?.data.doesSecretKeyFound)
          // window.$('#onlyinput').modal('show')
          // setOpen(false)
          toast.error(err.response?.data.msg, {
            position: "top-center",
            autoClose: 2000,
          });
        });
    } else {
      toast.error("Comments cannot be Empty", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const getAllComents = () => {
    // setOpen(true)
    axios
      .post(
        API_URL + "comment/getAllCommentByProposalId",
        { proposalId: id },
        { headers: { Authorization: `Bearer ${token?.token}` } }
      )
      .then((response) => {
        setAllCommentss(response.data.data);
      })
      .catch((err) => {
        // setOpen(false)
        if (err.toString().slice(39, 43) === "401") {
          userLogin();
        }
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  // console.log("data we have here", userInfo);

  useEffect(() => {
    gata();
    // fetchData();
    if (userInfo) {
      gata();
    }
  }, [id, account, userInfo]);

  useEffect(() => {
    Delgatecall();
    // fetchData();
    if (id) {
      getAllComents();
      getProposals();
      userNFt();
      // gata()
    }
  }, [id, account, address]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const trustWallet = async () => {
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    // console.log(account, "account");
    if (account) {
      logout();
      localStorage.clear();
    } else {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      handleClosewalletmobile();
      // userRegister()
      // localStorage.setItem('connectorId', 'walletconnect');
      window.$("#exampleModalwallet1").modal("hide");
      // handleClose()
    }
  };

  return (
    <>
      {mainLoader && <Loader />}

      <section className="banner-main banner-main ">
        <img src="\assets\shaade.png" className="linesshades img-fluid"></img>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              {/* <div className="d-flex topTicket">
                                <p>Treasury</p>
                                <p>28,307</p>
                            </div> */}
              <div className="pioneerDao">
                <div className="governancebox topBtnsDiv">
                  <div className="main_mid">
                    <div className="mainnnny">
                      <div className="textbtn">
                        <h5 >Proposal {id >= 21 ? parseInt(id - 1) : id}</h5>
                        <button
                          className={
                            ProposalUserStatus === "Succeeded" ||
                            (ProposalUserStatus === "Executed" && proposalPostState === "Succeeded")
                              ? "greyButtons border-0 bg-primary "
                              : ProposalUserStatus === "Pending" || ProposalUserStatus === "Active"
                              ? "greyButtons border-0 bg-secondary"
                              : ProposalUserStatus === "Vetoed" ||
                                ProposalUserStatus === "Canceled" ||
                                ProposalUserStatus === "Defeated" ||
                                proposalPostState === "Defeated"
                              ? "greyButtons border-0 bg-danger "
                              : ""
                          }
                        >
                          {ProposalUserStatus === "Executed" && proposalPostState === "Defeated"
                            ? "Defeated"
                            : ProposalUserStatus}
                        </button>
                      </div>
                      {/* <div>
                                            <button className='newwwwwbutton'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M12 2.5C6.49 2.5 2 6.99 2 12.5C2 18.01 6.49 22.5 12 22.5C17.51 22.5 22 18.01 22 12.5C22 6.99 17.51 2.5 12 2.5ZM16.35 16.07C16.21 16.31 15.96 16.44 15.7 16.44C15.57 16.44 15.44 16.41 15.32 16.33L12.22 14.48C11.45 14.02 10.88 13.01 10.88 12.12V8.02C10.88 7.61 11.22 7.27 11.63 7.27C12.04 7.27 12.38 7.61 12.38 8.02V12.12C12.38 12.48 12.68 13.01 12.99 13.19L16.09 15.04C16.45 15.25 16.57 15.71 16.35 16.07Z" fill="white" />
                                            </svg>Starts in 14 hours</button>
                                        </div> */}
                    </div>

                    <div className="flex-wrap mydataa">
                      <h3>{proposal?.title}</h3>
                      {/* <h5>Posted By: <span> 0x47aec...c7e9</span> at <span>0x314ce...54a</span></h5> */}
                      <div className="texted_mainns">
                        <div className="texted">
                          {/* <p>Voting ended {(new Date(proposal?.endTime * 1000)).toLocaleDateString()}.</p> */}
                          {/* <p>
                        This proposal has a reached Consensus of{" "}
                        {proposal?.legacy == true ? (
                          <>
                            {`${(userInfo?.forVotes / userInfo?.consensusVotes) * 100 >= 100
                                ? "100"
                                : (userInfo?.forVotes / userInfo?.consensusVotes) * 100 < 100
                                  ? ((userInfo?.forVotes / userInfo?.consensusVotes) * 100).toFixed(2)
                                  : "0"
                              } %`}
                          </>
                        ) : (
                          <>
                            {`${(userInfo?.forVotes / 10 ** 18 / userInfo?.consensusVotes) * 100 >=
                                100
                                ? "100"
                                : (userInfo?.forVotes / 10 ** 18 / userInfo?.consensusVotes) * 100 <
                                  100
                                  ? (
                                    (userInfo?.forVotes / 10 ** 18 / userInfo?.consensusVotes) *
                                    100
                                  ).toFixed(2)
                                  : "0"
                              } %`}
                          </>
                        )}
                      </p> */}
                          <h5>
                            Proposed by{" "}
                            <span className="">
                              {" "}
                              {proposal?.proposer.slice(0, 5) +
                                "........." +
                                proposal?.proposer.substr(proposal?.proposer.length - 5)}
                            </span>{" "}
                            at{" "}
                            <span>
                              <a
                                className="blank__div"
                                href={`https://etherscan.io/tx/${proposal?.transaction?.id}`}
                                target="_blank"
                              >
                                {proposal?.transaction?.id.slice(0, 5) +
                                  "........." +
                                  proposal?.transaction?.id.substr(
                                    proposal?.transaction?.id.length - 5
                                  )}
                              </a>
                            </span>{" "}
                          </h5>
                        </div>
                        <div className="votesDiv">
                          <>
                            {/* {voteStatus?.hasVoted === true && ProposalUserStatus === 'Active' ? <p className=''>You Have Already Voted</p>
        : ''
    } */}
                          </>
                          {account ? (
                            proposal?.legacy == true ? (
                              <>
                                {a < proposal?.startTime &&
                                (VoteAvail < 1 || VoteAvail < userTomiBalance) ? (
                                  <button className="subbmit__vote" onClick={delegateVote}>
                                    Delegate Your Vote
                                  </button>
                                ) : (
                                  <>
                                    {a > proposal?.startTime &&
                                    a < proposal?.endTime &&
                                    voteStatus === false ? (
                                      VoteAvail > 0 ? (
                                        <button
                                          className="bg-pink mb-0"
                                          data-toggle="modal"
                                          data-target="#exampleModalvote"
                                        >
                                          Submit Vote
                                        </button>
                                      ) : (
                                        <p>You Can't vote </p>
                                      )
                                    ) : ProposalUserStatus === "Succeeded" ||
                                      ProposalUserStatus === "Defeated" ? (
                                      <button className="bg-pink mb-0" onClick={ExecuteProposal}>
                                        Execute Proposal
                                      </button>
                                    ) : a < proposal?.startTime ? (
                                      "Voting Not Started Yet"
                                    ) : voteStatus === true && ProposalUserStatus === "Active" ? (
                                      <p className="">You Have Already Voted </p>
                                    ) : parseInt(userInfo.forVotes / 10 ** 18) +
                                        parseInt(userInfo.againstVotes / 10 ** 18) +
                                        parseInt(userInfo.againstVotes / 10 ** 18) >
                                        parseInt(userInfo.quorumVotes / 10 ** 18) &&
                                      (parseInt(userInfo?.forVotes / 10 ** 18) /
                                        parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                        100 >=
                                        "100" ? (
                                      <p>
                                        This propsal has reached a{" "}
                                        {(userInfo?.forVotes /
                                          (userInfo?.consensusVotes / 10 ** 18)) *
                                          100 >=
                                        100
                                          ? "100"
                                          : (parseInt(userInfo?.forVotes / 10 ** 18) /
                                              parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                              100 <
                                            100
                                          ? (
                                              (parseInt(userInfo?.forVotes / 10 ** 18) /
                                                parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                              100
                                            ).toFixed(2)
                                          : "0"}
                                        % of Consensus and{" "}
                                        {parseInt(userInfo?.forVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) >=
                                        parseInt(userInfo.quorumVotes / 10 ** 18)
                                          ? "100"
                                          : (
                                              ((parseInt(userInfo.forVotes / 10 ** 18) +
                                                parseInt(userInfo.abstainVotes / 10 ** 18) +
                                                parseInt(userInfo.againstVotes / 10 ** 18)) /
                                                parseInt(userInfo.quorumVotes / 10 ** 18)) *
                                              100
                                            ).toFixed(2)}
                                        % of Quorum so its {proposalPostState}
                                      </p>
                                    ) : parseInt(userInfo.forVotes / 10 ** 18) +
                                        parseInt(userInfo.againstVotes / 10 ** 18) +
                                        parseInt(userInfo.againstVotes / 10 ** 18) <
                                        parseInt(userInfo.quorumVotes / 10 ** 18) &&
                                      (parseInt(userInfo?.forVotes / 10 ** 18) /
                                        parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                        100 >=
                                        "100" ? (
                                      <p>
                                        This propsal has reached a{" "}
                                        {(userInfo?.forVotes /
                                          10 ** 18 /
                                          (userInfo?.consensusVotes / 10 ** 18)) *
                                          100 >=
                                        100
                                          ? "100"
                                          : (userInfo?.forVotes /
                                              10 ** 18 /
                                              (userInfo?.consensusVotes / 10 ** 18)) *
                                              100 <
                                            100
                                          ? (
                                              (parseInt(userInfo?.forVotes / 10 ** 18) /
                                                parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                              100
                                            ).toFixed(2)
                                          : "0"}
                                        % of Consensus and{" "}
                                        {parseInt(userInfo?.forVotes / 10 ** 18) +
                                          parseInt(userInfo?.againstVotes / 10 ** 18) +
                                          parseInt(userInfo?.againstVotes / 10 ** 18) >=
                                        parseInt(userInfo?.quorumVotes / 10 ** 18)
                                          ? "100"
                                          : (
                                              ((parseInt(userInfo?.forVotes / 10 ** 18) +
                                                parseInt(userInfo?.abstainVotes / 10 ** 18) +
                                                parseInt(userInfo?.againstVotes / 10 ** 18)) /
                                                parseInt(userInfo?.quorumVotes / 10 ** 18)) *
                                              100
                                            ).toFixed(2)}
                                        % of Quorum so its {proposalPostState}
                                      </p>
                                    ) : parseInt(userInfo?.forVotes / 10 ** 18) +
                                        parseInt(userInfo?.againstVotes / 10 ** 18) +
                                        parseInt(userInfo?.againstVotes / 10 ** 18) <
                                        parseInt(userInfo?.quorumVotes / 10 ** 18) &&
                                      (parseInt(userInfo?.forVotes / 10 ** 18) /
                                        parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                        100 <=
                                        "100" ? (
                                      <p>
                                        This propsal has reached a{" "}
                                        {(userInfo?.forVotes /
                                          10 ** 18 /
                                          userInfo?.consensusVotes) *
                                          100 >=
                                        100
                                          ? "100"
                                          : (userInfo?.forVotes /
                                              10 ** 18 /
                                              (userInfo?.consensusVotes / 10 ** 18)) *
                                              100 <
                                            100
                                          ? (
                                              (userInfo?.forVotes /
                                                10 ** 18 /
                                                (userInfo?.consensusVotes / 10 ** 18)) *
                                              100
                                            ).toFixed(2)
                                          : "0"}
                                        % of Consensus and{" "}
                                        {parseInt(userInfo.forVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) >=
                                        parseInt(userInfo.quorumVotes / 10 ** 18)
                                          ? "100"
                                          : (
                                              ((parseInt(userInfo?.forVotes / 10 ** 18) +
                                                parseInt(userInfo?.abstainVotes / 10 ** 18) +
                                                parseInt(userInfo?.againstVotes / 10 ** 18)) /
                                                parseInt(userInfo?.quorumVotes / 10 ** 18)) *
                                              100
                                            ).toFixed(2)}
                                        % of Quorum so its {proposalPostState}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <>
                                  {a < proposal?.startTime &&
                                  (VoteAvail < 1 || VoteAvail < userTomiBalance) ? (
                                    <button className="subbmit__vote" onClick={delegateVote}>
                                      Delegate Your Vote
                                    </button>
                                  ) : (
                                    <>
                                      {a > proposal?.startTime &&
                                      a < proposal?.endTime &&
                                      voteStatus === false ? (
                                        VoteAvail > 0 ? (
                                          <button onClick={handleShow} className="subbmit__vote">
                                            Submit Vote
                                          </button>
                                        ) : (
                                          // <button
                                          //   className="subbmit__vote"
                                          //   data-toggle="modal"
                                          //   data-target="#exampleModalvote"
                                          // >
                                          //   Submit Vote
                                          // </button>
                                          <p>You Can't vote </p>
                                        )
                                      ) : ProposalUserStatus === "Succeeded" ||
                                        ProposalUserStatus === "Defeated" ? (
                                        <button className="bg-pink mb-0" onClick={ExecuteProposal}>
                                          Execute Proposal
                                        </button>
                                      ) : a < proposal?.startTime ? (
                                        "Voting Not Started Yet"
                                      ) : voteStatus === true && ProposalUserStatus === "Active" ? (
                                        <p className="">You Have Already Voted </p>
                                      ) : parseInt(userInfo.forVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) >
                                          parseInt(userInfo.quorumVotes / 10 ** 18) &&
                                        (parseInt(userInfo?.forVotes / 10 ** 18) /
                                          parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                          100 >=
                                          "100" ? (
                                        <p>
                                          This propsal has reached a{" "}
                                          {(userInfo?.forVotes /
                                            10 ** 18 /
                                            (userInfo?.consensusVotes / 10 ** 18)) *
                                            100 >=
                                          100
                                            ? "100"
                                            : (parseInt(userInfo?.forVotes / 10 ** 18) /
                                                parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                                100 <
                                              100
                                            ? (
                                                (parseInt(userInfo?.forVotes / 10 ** 18) /
                                                  parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                                100
                                              ).toFixed(2)
                                            : "0"}
                                          % of Consensus and{" "}
                                          {parseInt(userInfo?.forVotes / 10 ** 18) +
                                            parseInt(userInfo.againstVotes / 10 ** 18) +
                                            parseInt(userInfo.againstVotes / 10 ** 18) >=
                                          parseInt(userInfo.quorumVotes / 10 ** 18)
                                            ? "100"
                                            : (
                                                ((parseInt(userInfo.forVotes / 10 ** 18) +
                                                  parseInt(userInfo.abstainVotes / 10 ** 18) +
                                                  parseInt(userInfo.againstVotes / 10 ** 18)) /
                                                  parseInt(userInfo.quorumVotes / 10 ** 18)) *
                                                100
                                              ).toFixed(2)}
                                          % of Quorum so its {proposalPostState}
                                        </p>
                                      ) : parseInt(userInfo.forVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) +
                                          parseInt(userInfo.againstVotes / 10 ** 18) <
                                          parseInt(userInfo.quorumVotes / 10 ** 18) &&
                                        (parseInt(userInfo?.forVotes / 10 ** 18) /
                                          parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                          100 >=
                                          "100" ? (
                                        <p>
                                          This propsal has reached a{" "}
                                          {(userInfo?.forVotes /
                                            10 ** 18 /
                                            (userInfo?.consensusVotes / 10 ** 18)) *
                                            100 >=
                                          100
                                            ? "100"
                                            : (userInfo?.forVotes /
                                                10 ** 18 /
                                                (userInfo?.consensusVotes / 10 ** 18)) *
                                                100 <
                                              100
                                            ? (
                                                (parseInt(userInfo?.forVotes / 10 ** 18) /
                                                  parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                                100
                                              ).toFixed(2)
                                            : "0"}
                                          % of Consensus and{" "}
                                          {parseInt(userInfo?.forVotes / 10 ** 18) +
                                            parseInt(userInfo?.againstVotes / 10 ** 18) +
                                            parseInt(userInfo?.againstVotes / 10 ** 18) >=
                                          parseInt(userInfo?.quorumVotes / 10 ** 18)
                                            ? "100"
                                            : (
                                                ((parseInt(userInfo?.forVotes / 10 ** 18) +
                                                  parseInt(userInfo?.abstainVotes / 10 ** 18) +
                                                  parseInt(userInfo?.againstVotes / 10 ** 18)) /
                                                  parseInt(userInfo?.quorumVotes / 10 ** 18)) *
                                                100
                                              ).toFixed(2)}
                                          % of Quorum so its {proposalPostState}
                                        </p>
                                      ) : parseInt(userInfo?.forVotes / 10 ** 18) +
                                          parseInt(userInfo?.againstVotes / 10 ** 18) +
                                          parseInt(userInfo?.againstVotes / 10 ** 18) <
                                          parseInt(userInfo?.quorumVotes / 10 ** 18) &&
                                        (parseInt(userInfo?.forVotes / 10 ** 18) /
                                          parseInt(userInfo?.consensusVotes / 10 ** 18)) *
                                          100 <=
                                          "100" ? (
                                        <p>
                                          This propsal has reached a{" "}
                                          {(userInfo?.forVotes /
                                            10 ** 18 /
                                            (userInfo?.consensusVotes / 10 ** 18)) *
                                            100 >=
                                          100
                                            ? "100"
                                            : (userInfo?.forVotes /
                                                10 ** 18 /
                                                (userInfo?.consensusVotes / 10 ** 18)) *
                                                100 <
                                              100
                                            ? (
                                                (userInfo?.forVotes /
                                                  10 ** 18 /
                                                  (userInfo?.consensusVotes / 10 ** 18)) *
                                                100
                                              ).toFixed(2)
                                            : "0"}
                                          % of Consensus and{" "}
                                          {parseInt(userInfo.forVotes / 10 ** 18) +
                                            parseInt(userInfo.againstVotes / 10 ** 18) +
                                            parseInt(userInfo.againstVotes / 10 ** 18) >=
                                          parseInt(userInfo.quorumVotes / 10 ** 18)
                                            ? "100"
                                            : (
                                                ((parseInt(userInfo?.forVotes / 10 ** 18) +
                                                  parseInt(userInfo?.abstainVotes / 10 ** 18) +
                                                  parseInt(userInfo?.againstVotes / 10 ** 18)) /
                                                  parseInt(userInfo?.quorumVotes / 10 ** 18)) *
                                                100
                                              ).toFixed(2)}
                                          % of Quorum so its {proposalPostState}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </>
                              </>
                            )
                          ) : (
                            "Connect wallet first"
                          )}
                        </div>
                      </div>

                      {/* {a < proposal?.endTime ? */}

                      {/* : <button className='exii'>Executed</button>} */}
                    </div>
                  </div>
                  <div className="randomtext"></div>
                </div>
              </div>
              <div className="middle_proposal_section">
                <div className="countSection">
                  <div className="parent__against">
                    <div className="">
                      <div className="">
                        <div className="forAganist ">
                          <p onClick={show}>For</p>
                          <h3>{parseInt(userInfo?.forVotes / 10 ** 18)}</h3>
                        </div>
                      </div>
                      <div className="">
                        <div className="forAganist midlee_div_center">
                          <p className="text-dangerr" onClick={show}>Against</p>
                          <h3>{parseInt(userInfo?.againstVotes / 10 ** 18)}</h3>
                        </div>
                      </div>
                      <div className="">
                        <div className="forAganist midlee_div_center">
                          <p className="text-secondary">Abstain</p>
                          <h3>{parseInt(userInfo?.abstainVotes / 10 ** 18)}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="holders_div">
                    <div className="">
                      <div className="ThresholdDiv">
                        <div className="innner__Data">
                          <p>Threshold Consensus</p>
                          {/* <p className="qutoa">
                            {new Intl.NumberFormat().format(parseInt(userInfo?.consensusVotes))} Votes
                          </p> */}
                        </div>
                        <div>
                          <h6>Quorum</h6>
                          <p className="qutoa">
                            {parseInt(userInfo?.quorumVotes / 10 ** 18).toLocaleString()} Votes
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="ThresholdDiv">
                        {proposal?.startTime > a ? (
                          <>
                            <p>Voting Start at</p>
                            <div>
                              <p className="">
                                {new Date(proposal?.startTime * 1000).toGMTString().slice(0, 16)}
                              </p>
                              <p className="qutoa">
                                {" "}
                                {new Date(proposal?.startTime * 1000).toGMTString().slice(17, 30)}
                              </p>
                              {/* <p className='qutoa'>July 10, 2022</p> */}
                            </div>
                          </>
                        ) : (
                          <>
                            <p>{a < proposal?.endTime ? "Voting Closes On" : "Ended"}</p>
                            <div>
                              <p className="qutoaaa">
                                {" "}
                                {new Date(proposal?.endTime * 1000).toGMTString().slice(17, 30)}
                              </p>
                              <p className="">
                                {new Date(proposal?.endTime * 1000).toGMTString().slice(0, 16)}
                              </p>

                              {/* <p className='qutoa'>July 10, 2022</p> */}
                            </div>
                          </>
                        )}
                        {/* <p>{a < proposal?.endTime ? "Voting Closes On" : "Ended"}</p>
                      <div>
                        <p className="">
                          {new Date(proposal?.endTime * 1000).toGMTString().slice(0, 16)}
                        </p>
                        <p className="qutoa">
                          {" "}
                          {new Date(proposal?.endTime * 1000).toGMTString().slice(17, 30)}
                        </p>
                        <p className='qutoa'>July 10, 2022</p>
                      </div> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="ThresholdDiv">
                        <p>Snapshot</p>
                        <div>
                          <p className="thenstate">Taken at block</p>
                          <p className="qutosa">15080360</p>
                        </div>
                      </div>
                    </div>
                    {/* <button onClick={handleShow} className="subbmit__vote">
                      Submit Vote
                    </button> */}
                  </div>
                </div>
                <div className="pioneerDao">
                  <div className="governancebox topBtnsDiv descriptiondd">
                    <h3 className="mainheaddd">Description</h3>

                    {/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.
                                    Et harum quidem rerum facilis est et expedita distinctio.
                                </p>
                                <p className='mt-4'>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p> */}
                    <div
                      className="read-article-paragraph"
                      dangerouslySetInnerHTML={{
                        __html: proposal?.description,
                      }}
                    ></div>
                  </div>
                  <div className="comment">
                    <div className="headingsss">
                      <h2>Comments</h2>
                    </div>
                    {allCommentss?.map((elem, i) => {
                      return (
                        <>
                          <div className="parent">
                            <div className="left-content">
                              <img
                                src={elem?.user ? elem?.user.picture : "landingavatar-03.png"}
                                className="img-fluid"
                              />
                            </div>
                            <div className="right-content">
                              <div className="doyle">
                                <h4 className="doylehead">
                                  {elem?.user ? elem?.user.name : "0x0032....849de"}
                                </h4>

                                <p>{elem.createdAt.split("T")[0]}</p>
                              </div>
                            </div>
                          </div>
                          <div className="mainpara-comment">
                            <div className="para">
                              <p>{elem?.comment} </p>
                            </div>
                          </div>
                          <div className="brdr"></div>
                        </>
                      );
                    })}
                  </div>
                  <div className="comments">
                    <div className="comments-heading">
                      <h2 onClick={show}>Leave a comment</h2>
                    </div>
                    <div className="commentstextarea">
                      <p>Comment</p>
                      <textarea
                        className="commentstext"
                        name="comment"
                        value={inputs.comment}
                        placeholder="Write comment"
                        onChange={handleChange1}
                      />
                    </div>
                    <div className="btnpost">
                      {mainDisable === true ? (
                        <button className="wait btnnnna" disabled>
                          Post Comment
                        </button>
                      ) : (
                        <button onClick={addComment}>Post Comment</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="vote">
          <div
            class="modal fade"
            id="exampleModalvote"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div className="main-heading">
                    <h3>Confirm Transaction</h3>
                  </div>
                  <div className="vote-btn">
                    <button
                      className={vote === 1 ? "votefor1" : "votefor"}
                      onClick={() => setVote(1)}
                    >
                      Vote in favour
                    </button>
                    <button
                      className={vote === 0 ? "voteagainst1" : "voteagainst"}
                      onClick={() => setVote(0)}
                    >
                      Vote Against
                    </button>
                    <button
                      className={vote === 2 ? "voteabstain1" : "voteabstain"}
                      onClick={() => setVote(2)}
                    >
                      Abstain
                    </button>
                  </div>
                  <div className="confirmbtn">
                    <button className="" onClick={VoteFor}>
                      Submit Vote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="connectwalletmodal">
          <div
            class="modal fade"
            id="exampleModalwallet1"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div className="main-heading">
                    <h3>Connect Wallet</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M19.281 18.7198C19.3507 18.7895 19.406 18.8722 19.4437 18.9632C19.4814 19.0543 19.5008 19.1519 19.5008 19.2504C19.5008 19.349 19.4814 19.4465 19.4437 19.5376C19.406 19.6286 19.3507 19.7114 19.281 19.781C19.2114 19.8507 19.1286 19.906 19.0376 19.9437C18.9465 19.9814 18.849 20.0008 18.7504 20.0008C18.6519 20.0008 18.5543 19.9814 18.4632 19.9437C18.3722 19.906 18.2895 19.8507 18.2198 19.781L12.0004 13.5607L5.78104 19.781C5.64031 19.9218 5.44944 20.0008 5.25042 20.0008C5.05139 20.0008 4.86052 19.9218 4.71979 19.781C4.57906 19.6403 4.5 19.4494 4.5 19.2504C4.5 19.0514 4.57906 18.8605 4.71979 18.7198L10.9401 12.5004L4.71979 6.28104C4.57906 6.14031 4.5 5.94944 4.5 5.75042C4.5 5.55139 4.57906 5.36052 4.71979 5.21979C4.86052 5.07906 5.05139 5 5.25042 5C5.44944 5 5.64031 5.07906 5.78104 5.21979L12.0004 11.4401L18.2198 5.21979C18.3605 5.07906 18.5514 5 18.7504 5C18.9494 5 19.1403 5.07906 19.281 5.21979C19.4218 5.36052 19.5008 5.55139 19.5008 5.75042C19.5008 5.94944 19.4218 6.14031 19.281 6.28104L13.0607 12.5004L19.281 18.7198Z"
                          fill="#0E1120"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="Submitproposal">
                    <div className="iconimages">
                      {/* <div className="leftimage" onClick={connectMetaMask1}>
                      <img src="\landing\metamask 2.svg" className="img-fluid" />
                      <p>Metamask</p>
                    </div> */}

                      <div className="leftimage" onClick={trustWallet}>
                        <img src="\assets\wallet.svg" className="img-fluid" />
                        <p>WalletConnect</p>
                      </div>
                    </div>
                  </div>
                  <div className="clear">{/* <button>Clear WalletConnect Data</button> */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal className="submit-proposal-modal1" show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>
            Confirm Transaction
            <svg
              className="crossbutton_svvgg"
              onClick={handleClose}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M19.281 18.4971C19.3507 18.5668 19.406 18.6495 19.4437 18.7406C19.4814 18.8316 19.5008 18.9292 19.5008 19.0278C19.5008 19.1263 19.4814 19.2239 19.4437 19.3149C19.406 19.406 19.3507 19.4887 19.281 19.5584C19.2114 19.6281 19.1286 19.6833 19.0376 19.7211C18.9465 19.7588 18.849 19.7782 18.7504 19.7782C18.6519 19.7782 18.5543 19.7588 18.4632 19.7211C18.3722 19.6833 18.2895 19.6281 18.2198 19.5584L12.0004 13.3381L5.78104 19.5584C5.64031 19.6991 5.44944 19.7782 5.25042 19.7782C5.05139 19.7782 4.86052 19.6991 4.71979 19.5584C4.57906 19.4177 4.5 19.2268 4.5 19.0278C4.5 18.8287 4.57906 18.6379 4.71979 18.4971L10.9401 12.2778L4.71979 6.05839C4.57906 5.91766 4.5 5.72678 4.5 5.52776C4.5 5.32874 4.57906 5.13787 4.71979 4.99714C4.86052 4.85641 5.05139 4.77734 5.25042 4.77734C5.44944 4.77734 5.64031 4.85641 5.78104 4.99714L12.0004 11.2174L18.2198 4.99714C18.3605 4.85641 18.5514 4.77734 18.7504 4.77734C18.9494 4.77734 19.1403 4.85641 19.281 4.99714C19.4218 5.13787 19.5008 5.32874 19.5008 5.52776C19.5008 5.72678 19.4218 5.91766 19.281 6.05839L13.0607 12.2778L19.281 18.4971Z"
                fill="#0E1120"
              />
            </svg>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="vote-btn">
            <button
              className={vote === 1 ? "active" : ""}
              onClick={() => setVote(1)}
            >
              Vote in favour
            </button>
            <button
              className={vote === 0 ? "active" : ""}
              onClick={() => setVote(0)}
            >
              Vote Against
            </button>
            <button
              className={vote === 2 ? "active" : ""}
              onClick={() => setVote(2)}
            >
              Abstain
            </button>
          </div>
          <div className="subbmitedvote">
            <button className="" onClick={VoteFor}>
              Submit Vote
            </button>
          </div>

          {/* <button className="subbmitedvote">Submit Vote</button> */}

          {/* <Link to="/">
              <button>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.15859 10.87C9.05859 10.86 8.93859 10.86 8.82859 10.87C6.44859 10.79 4.55859 8.84 4.55859 6.44C4.55859 3.99 6.53859 2 8.99859 2C11.4486 2 13.4386 3.99 13.4386 6.44C13.4286 8.84 11.5386 10.79 9.15859 10.87Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.4112 4C18.3512 4 19.9112 5.57 19.9112 7.5C19.9112 9.39 18.4113 10.93 16.5413 11C16.4613 10.99 16.3713 10.99 16.2812 11" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.15875 14.56C1.73875 16.18 1.73875 18.82 4.15875 20.43C6.90875 22.27 11.4188 22.27 14.1688 20.43C16.5888 18.81 16.5888 16.17 14.1688 14.56C11.4288 12.73 6.91875 12.73 4.15875 14.56Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                Committees Proposal
                <span className="comingsoon_text">Coming soon</span>
              </button>
            </Link> */}
          {/* /artdaoproposal */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Executed;
